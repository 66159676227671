.errorMessages {
  position: fixed;
  top: 5px;
  right: 15px;
  z-index: 40;
  display: flex;
  flex-direction: column;
}

.errorMessage {
  background-color: rgba(255,0,40,.9);
  border-radius: $border-radius-md;
  color: $tint;
  display: inline-block;
  font-size: rem-calc(20px);
  font-weight: bold;
  padding: rem-calc(14px);
  margin-bottom: 1rem;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  transition: opacity .5s;
  opacity: 0;
}
