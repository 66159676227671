.bet-prompt{
    background: rgba($theme-orange, 0.1);
    padding: 0.5rem 1rem 0.5rem;

    @include media-breakpoint-down(xs) {
        padding-left: 0.675rem;
        padding-right: 0.675rem;
    }

    margin-bottom: 0.5rem;

    &--title{
        font: 700 0.875rem $font-base;
        color: $theme-dark;
        margin-bottom: 2px;
        padding-top: 0.2rem;
    }

    &--text{
        font: rem-calc(9px) $font-primary;
        color: $theme-dark;
        margin-bottom: 0.675rem;
    }

    &--link{
        font: 700 0.875rem $font-base;
        color: $theme-red;
        position: relative;
        display: inline-block;

        &:before{
            content: "";
            display: block;
            width: 1rem;
            height: 1rem;
            background: url('../images/bet-promt-arrow-right.svg') no-repeat;
            background-size: cover;
            position: absolute;
            right: -1rem;
            top: 50%;
            margin-top: -0.5rem;
        }
    }

    &--list{
        display: flex;
        align-items: center;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0.5rem;
        
        li{
            padding: 0 0.5rem;
        }

        .type{
            font: 700 rem-calc(9px) $font-primary;
            color: #182330;
            text-align: center;
            margin-bottom: 2px;
        }

        .stack{
            width: rem-calc(50px);
            height: rem-calc(50px);
            border-radius: 50%;
            border: 2px solid $theme-danger;
            display: flex;
            align-items: center;
            justify-content: center;
            font: 400 0.75rem $font-base;
            color: $theme-gray;
            padding: 0;
        }
    }

    &--size{
        font: 0.75rem $font-base;
        color: $theme-danger;
        margin-top: 0.5rem;
    }
}