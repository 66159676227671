.game {
    margin-bottom: 3rem;
    width: 100%;

    .simple-bar-custom {
        max-height: 70vh;

        @include media-breakpoint-down(sm) {
            max-height: 55vh;
            padding-bottom: 2rem;
        }

        @include media-breakpoint-down(xs) {
            max-height: 50vh;
        }
    }

    .horse-list {
        min-height: 40vh;

        &--categories {
            border-top: 1px solid $theme-light-gray;
            border-bottom: 1px solid $theme-light-gray;
        }

        &--title {
            padding: 0 0.5rem;
            letter-spacing: 0.6px;
            font: normal 1rem $font-base;
            color: $theme-gray;
            margin-bottom: 1rem;

            @include media-breakpoint-up(md) {
                font-size: rem-calc(20px);
                padding: 0 1rem;
                margin-bottom: 0.625rem;
            }
        }

        .widget-participant_toggle-block {
            min-width: 2.5rem;
            display: flex;
            justify-content: center;

            @include media-breakpoint-up(md) {
                min-width: rem-calc(44px);
            }
        }

        .widget-participant_toggler {
            width: 1rem;
            height: 1rem;
            background: url("../../images/arrow-right-red.svg") no-repeat center
                center;
            background-size: 0.875rem 0.875rem;
            transform: rotate(90deg);

            &[aria-expanded="true"] {
                transform: rotate(-90deg);
            }
        }

        .widget-participant_section {
            padding: 0.375rem 0;
        }

        .widget-participant_name {
            padding: 0;
            font-size: 0.875rem;
            line-height: 1.4;

            @include media-breakpoint-up(md) {
                font-size: 1rem;
                line-height: 1.4;
            }
        }

        .widget-participant_list li {
            margin-top: 0;
            font-size: 0.625rem;
            line-height: 1.2;
        }

        .widget-participant_main {
            padding-left: 0.75rem;

            @include media-breakpoint-up(md) {
                padding-left: 1.5rem;
            }
        }

        .widget-participant_aside {
            padding-left: 0;

            @include media-breakpoint-up(md) {
                padding-left: 0.625rem;
            }
        }
    }

    .horse-sorting {
        &--wrapper {
            justify-content: unset;
        }

        &--spacer {
            width: 70%;

            &-small {
                width: 10%;
            }
            &-left {
                padding-left: rem-calc(82px);

                @include media-breakpoint-up(md) {
                    padding-left: rem-calc(112px);
                }
            }
        }

        &--item {
            min-width: unset;
            text-align-last: left;
            padding-left: 0;

            &-wrap {
                flex: 1;
                align-items: center;
                text-align: center;
                position: relative;
            }
        }

        &--tooltip {
            position: absolute;
            background-color: #fff;
            border-radius: 0.5rem;
            padding: 1rem;
            bottom: 200%;
            left: 50%;
            transform: translateX(-50%);
            border: 1px solid #e6e4e4;
            white-space: nowrap;

            &::after {
                position: absolute;
                display: block;
                content: "";
                width: 1rem;
                height: 1rem;
                transform: rotate(-45deg) translate(-50%);
                border-bottom: 1px solid #e6e4e4;
                border-left: 1px solid #e6e4e4;
                background-color: #fff;
                bottom: -3px;
                left: 50%;
            }
        }
    }
}
