.tutorial{
  margin-bottom: 1.5rem;
  background: $tint;
  padding: 2.5rem 1rem 0.5rem;

  &:last-child{
    margin-bottom: 0;
  }

  &_title{
    font: rem-calc(30px) $font-base;
    color: $black;
    margin-bottom: 1.25rem;
  }

  &_text{
    font: $font-size-base $font-base;
    color: $black;
  }
}
