.leagues {
    width: 100%;

    @include media-breakpoint-up(md) {
        min-height: 100vh;
    }

    &--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font: 400 0.625rem $font-primary;
        color: $gray-500;
        padding: 0.5rem 3rem 0.5rem 0.625rem;
        background: $white;
        border-top: 1px solid $theme-light-gray;

        @include media-breakpoint-up(md) {
            padding-left: 1rem;
            font-size: 0.75rem;
        }

        &-name,
        &-move {
            min-width: 3rem;
            text-align: center;
        }

        .horse-sorting--item {
            min-width: unset;
            padding-left: 0;
        }
    }

    &--name {
        font: 1.25rem $font-base;
        color: $theme-gray;
        padding: 1rem;
        background: $white;
        border-top: 1px solid $theme-light-gray;
        border-bottom: 1px solid $theme-light-gray;
    }

    &--tabs {
        justify-content: flex-start;
        background: $white;
        border-bottom: 1px solid $theme-light-gray;

        .nav-item {
            flex: auto;
            flex-grow: initial;
            width: 100%;
            max-width: rem-calc(200px);

            @include media-breakpoint-down(md) {
                max-width: none;
                flex-grow: 1;
                flex: 1;
            }

            .nav-link {
                height: rem-calc(51px);
                width: 100%;

                &:before {
                    height: 0.25rem;
                }
            }

            &.active {
                border-bottom-width: 0.25rem;
            }
        }
    }

    &--card-container {
        justify-content: center;
        margin-top: 0.75rem;
    }

    &--message-info {
        padding: 0.5rem 0 1rem;

        @include media-breakpoint-down(sm) {
            padding: 0.875rem 1rem 1.375rem;
        }
    }

    &--card {
        padding: 1rem 0;

        &_title {
            font: 0.875rem $font-base;
            color: $theme-dark;
        }

        &_button-container {
            margin-top: 1.5rem;
            padding: 0 1rem 3rem;

            @include media-breakpoint-down(sm) {
                button {
                    width: 100%;
                }
            }
        }
    }

    &--form {
        &_section {
            padding: 2.5rem 1rem 2.5rem;
            border: 1px solid $theme-light-gray;

            @include media-breakpoint-down(sm) {
                padding-bottom: 0;
            }
        }

        &_title {
            font: 1.25rem $font-base;
            color: $theme-gray;
        }

        &_label {
            font: 1.125rem $font-base;
            line-height: 1.38;
            text-align: left;
            color: #626567;
            margin: 0;
            position: absolute;
            top: 1rem;
            left: $input-padding-x;
            transition: all ease-in-out 0.25s;
        }

        .form-group {
            position: relative;
        }

        .form-group.active,
        .form-group.focused,
        .form-group.readonly {
            label {
                color: $theme-gray;
                font-size: rem-calc(9px);
                top: 0;
                left: 0;
                transition: all ease-in-out 0.25s;
            }
        }

        &_error {
            padding: 0.675rem 0;
            font: 0.875rem $font-base;
            color: $red-100;
        }

        &_input {
            border: 0;
            border-bottom: 2px solid $theme-primary;
            width: 100%;
            height: 4rem;
            font-size: 1.125rem;
        }

        &_dropdown {
            width: 60%;
            position: relative;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            button {
                width: 100%;
                background: transparent !important;
                border: 1px solid $tint-400 !important;
                font: 1rem $font-base;
                color: $black !important;
                text-align: left;
                position: relative;

                &:after {
                    content: "";
                    display: block;
                    width: 2rem;
                    height: 1.1rem;
                    background-image: url("../../images/up-arrow-red.svg");
                    background-repeat: no-repeat;
                    background-size: 0.875rem;
                    border: none;
                    transform: rotate(180deg);
                    position: absolute;
                    right: 1.25rem;
                    top: 50%;
                    margin-top: -0.55rem;
                }

                &:hover {
                    border-width: 1px;
                    background: transparent !important;
                    border-color: $tint-400 !important;
                    color: $black;
                }
            }

            .simple-bar-custom {
                max-height: 250px;
            }

            .dropdown-menu {
                width: 100%;
            }

            .dropdown-item {
                padding: 1rem;
                font: 1rem $font-base;
                color: $black;

                &:focus,
                &:active,
                &:hover {
                    background-color: $white;
                }
            }
        }
    }

    &--state {
        &-success,
        &-form {
            @include media-breakpoint-down(sm) {
                .leagues-main-preview {
                    display: none;
                }
            }
        }
    }

    &--finished {
        .leagues--card {
            &_title {
                font: 1rem $font-base;
                color: $theme-dark;
                margin-top: 1.25rem;
            }

            &_info {
                background: $active-box-bg;
                padding: 1rem;

                &-title {
                    font: 1rem $font-base;
                    color: $theme-gray;
                }

                &-league-name {
                    font: rem-calc(30px) $font-base;
                    color: $theme-primary;
                }
            }

            &_button-line {
                display: flex;
                margin-top: 1.25rem;

                button {
                    flex-grow: 1;
                }
            }
        }
    }

    &--preview {
        background: $theme-primary;
        font: rem-calc(30px) $font-base;
        color: $white;
        padding: 1.25rem 1rem;
        text-align: center;
        margin-top: 0.75rem;
        background: url("../../images/background-sign-up.png") no-repeat center
            top;
        background-size: 100% auto;

        @include media-breakpoint-down(sm) {
            padding: 2.35rem 1rem;
            background: url("../../images/league-create-bg-mobile.png")
                no-repeat center;
            background-size: cover;
            text-align: left;

            span {
                display: block;
            }
        }
    }

    &--title {
        font: 400 1.25rem $font-base;
        color: $tint;
        padding: 1.125rem 0.675rem;
        background: $theme-primary;
        margin-top: 1.5rem;
        margin-bottom: 0.75rem;
        @include media-breakpoint-down(md) {
            background: transparent;
            color: $theme-dark-blue;
            margin-top: 1rem;
        }
    }

    &--finished {
        padding: 0 1rem;
    }

    &--horses-sorting {
        .horse-sorting {
            &--wrapper {
                padding-right: 0;

                @include media-breakpoint-up(md) {
                    padding-right: 0.5rem;
                }
            }
        }
    }

    &--sorting {
        .horse-sorting {
            &--wrapper {
                padding-right: 2.75rem;

                @include media-breakpoint-up(md) {
                    padding-right: 3.125rem;
                }
            }

            &--number {
                padding: 0 0.25rem;
                min-width: 2.5rem;
                font: 0.625rem $font-primary;
                text-align: center;
                color: $gray-500;

                @include media-breakpoint-up(md) {
                    min-width: 3.5rem;
                    font-size: 0.75rem;
                }
            }

            &--item {
                min-width: 3rem;

                @include media-breakpoint-up(md) {
                    min-width: 4rem;
                    padding: 0.25rem 0.625rem;
                }
            }

            &--stable {
                @include media-breakpoint-up(md) {
                    padding: 0.25rem 1rem 0.25rem 0.625rem;
                }
            }

            &--move {
            }
        }
    }

    .league {
        background: $tint;
        // padding: 1rem 2.75rem 1rem 1rem;
        font: 600 1rem $font-primary;
        line-height: 1;
        position: relative;
        cursor: pointer;
        border-top: 1px solid $theme-light-gray;
        // border-left: 1px solid $theme-light-gray;
        // display: flex;
        justify-content: space-between;
        // align-items: center;

        @include media-breakpoint-down(xs) {
            // padding: .625rem 2.75rem .625rem 1rem;
            font-size: 0.875rem;
            line-height: 1.4;
        }

        &:last-child {
            border-bottom: 1px solid $theme-light-gray;
        }

        &--cell {
            padding: 1rem;
            // border-right: 1px solid $theme-light-gray;
            text-align: center;
            min-width: 3rem;

            &-preseason{
                text-align: left;
            }
        }

        &--name {
            width: 100%;
            text-align: left;
            border-right: 1px solid $theme-light-gray;
            border-left: 1px solid $theme-light-gray;
        }

        &--position {
            font: 0.875rem $font-base;
            color: $theme-dark;
            border-right: 1px solid $theme-light-gray;
            white-space: nowrap;
            min-width: 3rem;

            &-up{
                color: #8ac644;
                display: inline-block;

            }
            &-down{
                color: #ff4908;
                display: inline-block;
                transform: rotate(180deg);
            }
        }

        &:before {
            content: " ";
            display: block;
            background: url("../../images/arrow-right-red.svg") no-repeat center
                center;
            background-size: 1.25rem 1.25rem;
            width: 2.75rem;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .league-detail {
        &--header {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.25rem 1rem;
            margin-bottom: 0.5rem;
            color: $white;
            background-color: $theme-primary;

            .title,
            .subtitle {
                margin-bottom: 0;
                font-size: 1rem;
                font-family: $font-base;
                line-height: 1.3;
                text-transform: initial;
            }
        }

        &--invite {
            padding: 0.675rem 2.5rem;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        .league-user-stable_points {
            display: flex;
            align-items: baseline;
            position: relative;
            min-height: 1.5rem;

            &:before {
                content: " ";
                display: block;
                background: url("../../images/arrow-right-red.svg") no-repeat
                    top right;
                background-size: 80%;
                width: 1.75rem;
                height: 1.75rem;
                position: absolute;
                right: 0px;
                top: 0;
            }
        }

        .widget-user-stable {
            &_body {
                .value {
                    padding-right: 1.75rem;
                    position: relative;
                }
            }
        }
    }

    &--detail-stable {
        max-height: 80vh;

        &-header {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.25rem 1rem;
            margin-bottom: 0.5rem;
            color: $white;
            background-color: $theme-primary;

            &_name,
            &_label,
            &_value {
                font-size: 1rem;
                font-family: $font-base;
                line-height: 1.3;
            }

            &_silk {
                img {
                    width: 3.75rem;
                    height: auto;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            padding-bottom: 2rem;
        }
    }

    &--detail-list {
        max-height: 65vh;
        padding-bottom: 2rem;

        @media (max-height: 900px) {
            max-height: 60vh;
        }

        @media (max-height: 700px) {
            max-height: 45vh;
            padding-bottom: 3.5rem;
        }

        @media (max-height: 500px) {
            padding-bottom: 5rem;
        }
    }

    .hidden-on-mobile {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .hidden-on-desktop {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .widget-participant.stable-detail.preseason {
        .widget-participant_section {
            padding-right: 0;
        }

        .widget-participant_padding {
            padding-right: 0;
        }
    }
}
