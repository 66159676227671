.perm-info{
  background: #f4f4f4;
  border: 1px solid $theme-light-gray;
  padding: 1.25rem 1rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin: 1rem;

  &--image img{
    height: rem-calc(47px);
    width: rem-calc(66px);
  }

  &--text{
    font: 1rem $font-base;
    color: #7e7e7e;
  }

  &--name{
    font: 1.5rem $font-base;
    color: $theme-dark;
    margin-bottom: 0.75rem;
  }

  &--stables{
    font: 0.75rem $font-base;
    color: #7e7e7e;
  }
}
