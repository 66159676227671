/* ----------------------------------------------------------------
	Home page
-----------------------------------------------------------------*/
$span-color: $gray-700;

.homepage {
    .header {
        margin-bottom: 0;
    }

    .main {
        background: $white;
        &:after {
            display: none;
        }
    }

    @include media-breakpoint-down(md) {
        .container {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}

.tote-mini-hero-img {
    position: absolute;
    background: $red-400 url("../../images/hero-image-mini-tote.png") no-repeat
        center;
    background-size: cover;
    height: 100%;
    width: 100vw;
    top: 0;
    left: -50vw;
    margin-left: 50%;
    z-index: 1;

    @include media-breakpoint-down(sm) {
        background-image: url("../../images/hero-image-mobile-mini-tote.png");
    }
}

.homePage {
    background-color: $white;
    width: 100%;

    &--info-line {
        position: relative;
        padding: 0.5rem 0.25rem 0.625rem 0.25rem;
        background-color: $red-100;
        color: $white;
        font-size: rem-calc(10px);
        font-family: $font-primary;
        line-height: 1.6;

        &.info-line-blue {
            background-color: $blue-100;
        }

        @include media-breakpoint-up(md) {
            padding: 1rem;
            font-size: 0.75rem;
            line-height: 1.4;
        }

        &_text {
            @extend .container;
            padding-top: 1.5rem;
            font-family: $font-secondary;
            font-style: normal;
            font-weight: 300;
            font-size: .5rem;
            line-height: 1.5;
            color: #FFFFFF;


            @include media-breakpoint-up(md) {
                font-size: .75rem;
                line-height: 1rem;
                padding-top: 0;
                padding-right: 14rem !important;
            }
        }

        &_img {
            position: absolute;
            right: 7%;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;

            @include media-breakpoint-down(sm) {
                right: auto;
                left: 1rem;
                top: 0.25rem;
                transform: translateY(0);
            }

            img {
                display: inline-block;
                max-width: rem-calc(137px);

                @include media-breakpoint-up(md) {
                    max-width: rem-calc(197px);
                }
            }
        }
    }

    &.season {
        .homePage--banner {
            @include media-breakpoint-down(sm) {
                padding-bottom: 0.5rem;
            }
        }

        .homePage--banner:before {
            display: none;
        }

        .homePage--banner_logo-begamble {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    @include media-breakpoint-down(md) {
        background-color: $body-bg;
    }

    &--title {
        font: rem-calc(40px) $font-base;
        color: $black;
        margin-bottom: 0.5rem;
    }

    &--text {
        font: 1rem $font-primary;
        color: $black;
        line-height: 1.5;
    }

    &--alert {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: #13EDC9;

        @include media-breakpoint-down(md) {
            padding-left: 0;
            padding-right: 0;

            .container {
                padding-left: 1rem;
                padding-right: 1rem;
                flex-direction: column;
            }
        }

        &_date {
            font: 1.25rem $font-base;
            color: #000;
            margin-right: rem-calc(40px);

            @include media-breakpoint-down(xs) {
                font-size: 1.125rem;
            }
        }
        &_text {
            font: 1.125rem $font-primary;
            color: #000;

            @include media-breakpoint-down(xs) {
                font-size: 0.75rem;
            }
        }
    }

    &--banner-blue {
        position: relative;
        padding: 1.75rem 1rem;
        background-color: $blue-100;
        min-height: 400px;
        display: flex;
        align-items: center;

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: right center;
            display: block;
            z-index: 0;

            @include media-breakpoint-down(sm) {
                object-position: left;
            }
        }

        .container {
            z-index: 1;
        }

        @include media-breakpoint-down(sm) {
            padding: 1.75rem 1rem;
            min-height: 400px;
        }
    }

    &--banner {
        position: relative;
        background: $theme-primary;
        padding: 5rem 1rem 1.75rem;

        &.tote-mini {
            padding: 1rem 0 3rem;

            @include media-breakpoint-up(md) {
                padding: 2rem 1rem 1.75rem;
            }
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            background: $theme-red url("../../images/hero-image.png") no-repeat
                center;
            background-size: contain;
            height: 100%;
            width: 100vw;
            top: 0;
            left: -50vw;
            margin-left: 50%;

            @include media-breakpoint-down(xs) {
                background-size: cover;
                background-image: url("../../images/hero-image-mobile.png");
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 4rem 0rem 4.5rem;
        }

        &_inner {
            position: relative;
            color: $white;
            z-index: 1;
        }

        &_links {
            margin-bottom: rem-calc(40px);

            @include media-breakpoint-down(sm) {
                margin-top: rem-calc(29px);
                margin-bottom: 0;
            }
        }

        &_link {
            max-width: 330px;
            width: 100%;

            @include media-breakpoint-down(xs) {
                max-width: 100%;
            }
        }

        &_text {
            font: 600 1.125rem $font-primary;
            line-height: 1;
            color: $white;
        }

        &_title{
            font-family: FlamaCond;
            font-size: 85px;
            font-weight: 800;
            transform: matrix(0.97, 0, -0.27, 1, 0, 0);
            text-transform: uppercase;
            line-height: 75px;

            @include media-breakpoint-up(md) {
                font-size: 110px;
                line-height: 80px;
            }
        }

        &_prize {
            font: 400 rem-calc(90px) $font-base;
            line-height: 1;
            margin-top: 1rem;

            &.tote-mini {
                @include media-breakpoint-up(md) {
                    font-size: rem-calc(70px);
                }
            }

            @include media-breakpoint-down(sm) {
                font-size: rem-calc(69px);
                text-transform: none;

                span {
                    display: block;
                }
            }

            @include media-breakpoint-down(xs) {
                font-size: 4rem;
            }
        }

        &_info {
            display: flex;
            flex-direction: column;
            font-family: $font-secondary;
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 1.2;
            color: #F2F1F3;
            margin-top: 2rem;

            @include media-breakpoint-down(sm) {
                flex-direction: column-reverse;
                font-size: .85rem;
                line-height: 1rem;
                margin-top: 1.25rem;
            }
        }

        &_caption {
            font: 400 1.125rem $font-primary;
            margin-bottom: 0.2rem;

            b {
                font-weight: 600;
            }
        }

        &_logo-begamble {
            position: absolute;
            right: rem-calc(30px);
            bottom: rem-calc(30px);
            z-index: 1;

            @include media-breakpoint-down(sm) {
                width: 100%;
                right: 0;
                left: 0;
                bottom: 0;
                background: $red-100;
                padding: 0.25rem 1rem;
            }
        }

        &_logo_wins {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: $theme-orange;
            border-radius: 50%;
            width: 6.25rem;
            height: 6.25rem;
            position: absolute;
            right: 10%;
            top: 10%;
            z-index: 1;
            transform: rotate(-8deg);

            .text {
                font: 0.75rem $font-base;
                color: #6a1a26;
                text-align: center;
                margin-bottom: 0.25rem;

                @include media-breakpoint-up(md) {
                    font-size: 1.125rem;
                }
            }

            .prize {
                color: #6a1a26;
                font: 1.125rem $font-base;

                @include media-breakpoint-up(md) {
                    font-size: 1.5rem;
                }
            }

            @include media-breakpoint-up(md) {
                right: auto;
                margin-left: -0.5rem;
                left: 54%;
                bottom: 3rem;
                width: 10rem;
                height: 10rem;
                top: auto;
            }

            @include media-breakpoint-up(lg) {
                bottom: 1rem;
                left: 52%;
            }

            @media only screen and (min-width: 1440px) {
                left: auto;
                right: 38%;
            }
        }
    }

    &--winner {
        padding-top: rem-calc(50px);

        &_block {
            margin-bottom: rem-calc(50px);
        }

        &_title {
            font: 1.25rem $font-base;
            color: $black;
            margin-bottom: 0.75rem;
        }

        &_description {
            font: 1rem $font-primary;
            color: $black;
            line-height: 1.5;
        }

        &_image {
            max-width: 100%;
        }
    }

    &--steps {
        padding: rem-calc(50px) 0 rem-calc(50px);

        @include media-breakpoint-down(sm) {
            padding: rem-calc(40px) 0 rem-calc(40px);
        }
    }

    &--instructions {
        background: $tint-200;
        padding: 4.375rem 0 0;

        &_link {
            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }
    }

    &--prizes {
        margin-top: rem-calc(50px);
        background: $white;

        @include media-breakpoint-down(md) {
            margin-top: 0;
            padding-top: rem-calc(40px);
        }

        .container {
            padding: 0 1rem;
        }

        .monthly-prizes {
            @include media-breakpoint-down(md) {
                background: transparent;
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    &--sign-up {
        background: url("../../images/background-sign-up.png") no-repeat center;
        background-size: cover;
        padding: 2.75rem 0;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                align-items: flex-start;
                padding: 0 1rem;
            }
        }

        @include media-breakpoint-down(xs) {
            background-image: url("../../images/background-sign-up-mobile.png");
        }

        &_title {
            font: rem-calc(40px) $font-base;
            color: $white;
            margin-bottom: 0;

            @include media-breakpoint-down(sm) {
                margin-bottom: 2rem;
                font-size: rem-calc(30px);
            }
        }

        &_link {
            @include media-breakpoint-down(xs) {
                max-width: 100%;
            }
        }
    }

    &--list {
        background: url("../../images/background-list.png") no-repeat center;
        background-size: cover;
        padding: 2.75rem 0;

        @include media-breakpoint-down(xs) {
            background-image: url("../../images/background-list-mobile.png");
        }

        &_container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }
        }

        &_label {
            font: 1.5rem $font-base;
            color: $theme-orange;
        }

        &_title {
            font: rem-calc(40px) $font-base;
            color: $white;

            @include media-breakpoint-down(sm) {
                margin-bottom: 2rem;
            }
        }

        &_link {
            a {
                padding-right: 5rem;
                white-space: nowrap;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

                &:after {
                    background-image: url("../../images/download.svg");
                }
            }
        }
    }

    &--top-horses {
        background: $tint-200;
        padding: rem-calc(68px) 0 rem-calc(95px);

        .title {
            font: rem-calc(30px) $font-base;
            color: #22344b;
            text-align: left;
            text-transform: none;
        }

        .text {
            font: 400 1rem $font-primary;
            color: $black;
        }

        .widget-participant {
            border: 1px solid $theme-light-gray;

            &_padding {
                padding-left: rem-calc(35px);
            }

            &_index {
                bottom: rem-calc(27px);
            }

            &_section {
                border-bottom: none;
            }
        }
    }

    &--feature {
        background: $tint-200;
        padding-top: rem-calc(50px);
        padding-bottom: rem-calc(100px);

        @include media-breakpoint-down(lg) {
            padding-top: rem-calc(30px);
            padding-bottom: rem-calc(40px);
        }

        @include media-breakpoint-down(md) {
            .container {
                padding-right: 1rem;
                padding-left: 1rem;
            }
        }

        &_title {
            font: rem-calc(40px) $font-base;
            color: $black;
            margin-bottom: 0.5rem;

            &-mobile {
                @include media-breakpoint-down(md) {
                    font: rem-calc(30px) $font-base;
                }
            }
        }

        &_text {
            font: 1rem $font-primary;
            color: $black;
            line-height: 1.5;
        }

        .feature {
            &--center {
                padding-top: 4rem;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            &--block {
                padding: 1rem 1.125rem;
                background: $white;
                box-shadow: 0 0 4px 0 rgba($black, 0.2);
                position: relative;

                &_own-silk {
                    margin-bottom: 5rem;

                    @include media-breakpoint-down(lg) {
                        margin-bottom: 0;
                    }
                }

                @include media-breakpoint-down(md) {
                    &:before {
                        content: "";
                        display: block;
                        background-repeat: no-repeat;
                        position: absolute;
                    }

                    &_edit-selection {
                        &:before {
                            width: rem-calc(62px);
                            height: rem-calc(89px);
                            background-image: url("../../images/arrow-two.svg");
                            left: -2.5rem;
                            top: -1rem;
                        }
                    }

                    &_own-silk {
                        &:before {
                            width: rem-calc(56px);
                            height: rem-calc(72px);
                            background-image: url("../../images/arrow-one.svg");
                            left: -1rem;
                            top: -2.5rem;
                        }
                    }

                    &_favourites {
                        &:before {
                            width: rem-calc(77px);
                            height: rem-calc(60px);
                            background-image: url("../../images/arrow-three.svg");
                            right: -2rem;
                            top: -3rem;
                        }
                    }
                }

                @include media-breakpoint-down(lg) {
                    width: rem-calc(242px);
                    margin: rem-calc(40px) auto 0;
                }

                &_images {
                    margin-bottom: 1rem;

                    img {
                        max-width: 100%;
                    }
                }

                &_title {
                    font: 400 1rem $font-base;
                    color: $black;
                    margin-bottom: 0.2rem;
                }

                &_text {
                    font: 400 0.75rem $font-primary;
                    color: $black;
                    line-height: 1.67;
                }

                &_find-stables,
                &_top_stables {
                    @include media-breakpoint-down(md) {
                        &:before {
                            background-size: contain;
                        }
                    }
                    @include media-breakpoint-up(md) {
                        max-width: 15rem;
                        position: relative;
                    }
                }

                &_transfer-closed {
                    padding-top: 0;
                    width: 95%;
                    margin: 0 auto;

                    &:after,
                    &:before {
                        content: "";
                        position: absolute;
                        display: block;
                        background-size: contain;
                        top: 50%;
                        z-index: 10;
                        transform: translateY(-50%);
                    }

                    &:before {
                        width: 5rem;
                        height: 2.75rem;
                        background: url("../../images/feature-right-arrow.png")
                            center center no-repeat;
                        left: -5%;
                    }

                    &:after {
                        width: 5.5rem;
                        height: 5.5rem;
                        background: url("../../images/feature-left-arrow.png")
                            center center no-repeat;
                        right: -5%;
                    }

                    img {
                        max-width: 100%;
                    }
                }

                &_find-stables {
                    @include media-breakpoint-down(md) {
                        margin-bottom: 1.5rem;

                        &:before {
                            width: 4rem;
                            height: 6rem;
                            background: url("../../images/feature-mobile-right-arrow.png")
                                center center no-repeat;
                            right: 90%;
                            bottom: 75%;
                        }
                    }
                }

                &_top_stables {
                    @include media-breakpoint-down(md) {
                        &:before {
                            width: 5.5rem;
                            height: 5.5rem;
                            background: url("../../images/feature-mobile-left-arrow.png")
                                center center no-repeat;
                            right: -20%;
                            bottom: 80%;
                        }
                    }
                    @include media-breakpoint-up(md) {
                        margin-left: auto;
                        margin-top: 0.75rem;
                    }
                }
            }
        }
    }

    .header {
        @include media-breakpoint-down(md) {
            display: flex;
        }
    }

    &_heading {
        font: 400 rem-calc(30px) $font-base;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(40px);
        }
        @media (max-width: 345px) {
            font: 400 1.6rem $font-base;
        }
    }

    &_title {
        font: 400 rem-calc(30px) $font-base;
    }

    &_subtitle {
        font: 600 1rem $font-primary;
        color: $black;
        @media (max-width: 345px) {
            font: 600 0.8rem $font-primary;
        }
    }

    &.is-transfer {
        .homePage--banner:before {
            display: none;
        }
        .card-instruction {
            &.is-left {
                margin-right: 0.75rem;
            }
            &.is-right {
                margin-left: 0.75rem;
            }
            &_text {
                line-height: 1.6;
                word-spacing: 0.25rem;
            }

            @include media-breakpoint-down(md) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .swiper-container {
            width: 100%;
        }

        .homePage--prizes {
            &_column {
                @include media-breakpoint-down(lg) {
                    &.is-left {
                        margin-bottom: 2.5rem;
                    }
                }

                @include media-breakpoint-up(lg) {
                    &.is-left {
                        padding-right: 2.75rem;
                    }
                    &.is-right {
                        padding-left: 2.75rem;
                        padding-right: 2.75rem;
                    }
                }
            }
            .monthly-prizes {
                margin-bottom: 2.5rem;
            }
        }
        .homePage--table_small-td {
            width: 5rem;

            @include media-breakpoint-up(md) {
                width: 9rem;
            }
        }
    }

    &--removals {
        background-color: $body-bg;
        position: relative;
        padding-top: 1.125rem;
        padding-bottom: 2.5rem;

        @include media-breakpoint-up(lg) {
            padding-top: 3.125rem;
            padding-bottom: 3.125rem;
        }

        &_heading {
            margin-bottom: 1rem;

            @include media-breakpoint-up(lg) {
                min-height: 4.75rem;
                margin-bottom: 1.25rem;
            }
        }

        &_column {
            &.is-left {
                margin-bottom: 2rem;
            }
            @include media-breakpoint-up(lg) {
                &.is-left {
                    padding-right: 1.25rem;
                    margin-bottom: 0;
                    &:after {
                        content: "";
                        width: 1px;
                        background: $theme-light-gray;
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: -0.5px;
                    }
                }

                &.is-right {
                    padding-left: 1.25rem;
                }
            }
        }

        .homePage--subtitle {
            color: $red-100;
        }

        .widget-participant_section {
            margin-bottom: 1rem;
            border-bottom: none;
            box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.15) !important;
            padding-top: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .widget-participant_silk {
            width: rem-calc(44px);
        }
    }
}

.comment {
    padding: rem-calc(46px) 0 rem-calc(52px);
    background: #fcfcfc;
    position: relative;

    @include media-breakpoint-down(md) {
        background: $tint-200;
    }

    .container {
        @include media-breakpoint-down(md) {
            padding: 0 1rem;
        }
    }

    &--info {
        position: relative;

        &:before {
            content: "â";
            font: rem-calc(250px) $font-base;
            color: #f4f5f6;
            position: absolute;
            left: -1.5rem;
            top: -5.5rem;
            z-index: 0;

            @include media-breakpoint-down(md) {
                color: $white;
            }
        }
    }

    &--title {
        font: rem-calc(40px) $font-base;
        color: $theme-dark;
        margin-bottom: rem-calc(50px);
        position: relative;

        @include media-breakpoint-down(md) {
            font-size: rem-calc(30px);
            margin-bottom: 1.25rem;
        }
    }

    &--description {
        font: 1.25rem $font-primary;
        color: $theme-dark;
        margin-bottom: 1.25rem;
        position: relative;

        @include media-breakpoint-down(md) {
            font-size: 1rem;
        }
    }

    &--author {
        font: 1.25rem $font-base;
        color: $red-100;
        margin-bottom: rem-calc(6px);
    }

    &--image {
        content: "";
        position: absolute;
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        right: 0;
        top: 0;
        bottom: 0;

        @include media-breakpoint-down(md) {
            display: none;
        }

        img {
            max-width: 100%;
            height: 100%;

            @include media-breakpoint-down(lg) {
                width: 100%;
                height: auto;
            }
        }
    }
}

.banner-btn {
    position: relative;
    text-align: left;
    background-color: $white;
    padding: 0.8rem 0.875rem 0.8rem 1.25rem;
    font: 600 1.25rem $font-primary;
    color: $theme-dark;

    &:after {
        content: "";
        display: block;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 0.5rem;
        height: 1.25rem;
        width: 1.25rem;
        background: url("../../images/arrow-right-red.svg") no-repeat center
            center;
        background-size: cover;
        @media (max-width: 345px) {
            right: 0.3rem;
        }
    }

    &-blue {
        &:after {
            right: 1.2rem;
            background: url("../../images/arrow-right-blue.svg") no-repeat
                center center;
        }
    }
}

.card--gray {
    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        height: 100%;
        padding: 1rem 3.5rem;
        border-radius: $border-radius;
        background: #f8f8f8;
    }
}

.card--white {
    background: $white;
}
.smart-pick {
    position: relative;
    display: flex;
    background: $tint-200;
    padding: 4.375rem 0 7.5rem;

    @include media-breakpoint-down(xs) {
        padding: 3.125rem 0 0;
    }

    &--title {
        position: relative;
        font-weight: 600;
        margin-bottom: 2.25rem;
        color: $blue-100;
        font-family: $font-base;
        font-size: $h1-large-font-size;

        span {
            padding: 0.375rem 0.8rem;
            background-color: $theme-red-alt;
            border-radius: $border-radius-sm;
            color: $tint-800;
            text-transform: uppercase;
            font-size: $h5-font-size;
            display: inline-block;
            transform: translateY(-26px);
            line-height: 1;

            @include media-breakpoint-down(xs) {
                padding: 0.375rem 0.625rem;
                font-size: $h6-font-size;
                transform: translateY(-8px);
            }
        }

        @media screen and (max-width: 1399px) {
            margin-bottom: 1.5rem;
            max-width: 32rem;
        }

        @include media-breakpoint-down(xs) {
            font-size: $h3-large-font-size;
            margin-bottom: 1.75rem;
            max-width: 16rem;
        }
    }

    &--img {        
        height: auto;
        display: none;

        @media (min-width: 1024px) {
            display: block;
            align-self: flex-end;
            margin-bottom: 2.5rem;
        }

        &-m {
            display: block;
            max-width: 100%;

            @media (min-width: 1024px) {
                display: none;
            }
        }
       
    }

    &--arrow-img {
        position: absolute;
        top: 80%;
        right: -3.5rem;
        z-index: 2;

        @include media-breakpoint-down(lg) {
            display: none;
        }

        &-m {
            display: none;

            @include media-breakpoint-down(xs) {
                display: block;
                position: absolute;
                bottom: -4rem;
                right: 2rem;
                z-index: 5;
            }
        }
    }

    &--list {
        padding-left: 1.5rem;
        max-width: 28rem;

        @include media-breakpoint-down(md) {
            max-width: none;
        }
    }

    &--item {
        margin-bottom: 1.875rem;

        &--title {
            font-weight: 600;
            font-size: $font-size-base;
            font-family: $font-secondary;

        }

        &--desc {
            font-weight: 300;
            font-size: $font-size-base;
            font-family: $font-secondary;
        }

        @media screen and (max-width: 1399px) {
            margin-bottom: 1rem;
        }
    }
}

.comment {
    padding: rem-calc(46px) 0 rem-calc(52px);
    background: #fcfcfc;
    position: relative;

    @include media-breakpoint-down(md) {
        background: $tint-200;
    }
}

.homePage_table {
    border: 1px solid $theme-primary;
    border-radius: $border-radius;
    @media (max-width: 345px) {
        overflow: hidden;
    }

    table {
        width: 100%;
        color: $black;
    }

    thead {
        th {
            padding: 0.75rem 0.4rem;
            font: 600 $small-font-size $font-primary;
            @include media-breakpoint-up(md) {
                padding: 0.75rem 1rem;
            }
            @media (max-width: 345px) {
                font: 600 10px $font-primary;
                padding: 0.75rem 0.2rem;
            }
        }
    }

    tbody {
        tr {
            border-top: 1px solid $theme-primary;
        }

        td {
            padding: 0.5rem 0.4rem;
            font: 400 $small-font-size $font-primary;
            @include media-breakpoint-up(md) {
                padding: 0.375rem 1rem;
                font-size: 1rem;
            }
            @media (max-width: 345px) {
                font: 400 10px $font-primary;
                padding: 0.5rem 0.2rem;
            }
        }
    }
}

.steps--inner {
    display: flex;
    margin: 0 -0.25rem;

    @include media-breakpoint-up(md) {
        margin: 0 -0.75rem;
    }
}

.card-step {
    flex: 1;
    margin: 0 0.25rem;
    padding: 1.5rem 0.25rem 0.75rem;
    border-radius: $border-radius;
    border: 1px solid $theme-primary;

    @include media-breakpoint-up(md) {
        margin: 0 0.75rem;
        padding: 2.25rem 0.5rem;
    }

    @include media-breakpoint-down(sm) {
        width: 25%;
    }

    @media (max-width: 345px) {
        margin: 0 0.2rem;
        padding: 1rem 0.15rem 0.75rem;
    }

    img,
    svg {
        display: block;
        margin: 0 auto 1.5rem;
        height: rem-calc(40px);

        @include media-breakpoint-up(md) {
            height: rem-calc(70px);
            margin: 0 auto 2.25rem;
        }

        @media (max-width: 345px) {
            height: 2rem;
            margin: 0 auto 1.2rem;
        }
    }

    &_text {
        font: 400 1rem $font-base;
        line-height: 1;
        text-align: center;
        margin-bottom: 0.875rem;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(22px);
            line-height: 1;
        }

        @media (max-width: 345px) {
            font: 400 rem-calc(14px) $font-base;
        }
    }

    &_description {
        font: 400 1rem $font-primary;
        text-align: center;
        line-height: 1.63;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.card-instruction {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;

    @include media-breakpoint-down(md) {
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &_subtitle {
        font: 1rem $font-base;
        color: $red-100;
        margin-bottom: 0.5rem;
    }

    &_title {
        font: rem-calc(30px) $font-base;
        color: $theme-dark;
        margin-bottom: 0.75rem;
    }
    &_text {
        font: 1rem $font-primary;
        color: #000;
    }

    &_image {
        min-width: rem-calc(300px);
        padding-left: 1.5rem;
        text-align: center;

        @include media-breakpoint-down(md) {
            padding-left: 0;
        }

        img {
            width: 100%;
            max-width: rem-calc(300px);
            height: auto;

            @include media-breakpoint-down(sm) {
                max-width: rem-calc(360px);
            }
        }
    }
}
