
@import "~swiper/dist/css/swiper.css";

.shared-gallery {

  .swiper-container {
    width: 100%;
  }

  .swiper-slide {
    text-align: center;

    img {
      width: 100%;
    }
  }
}

.swiper-container {
  margin: 0 auto 3rem;
  width: 90vw;
  
  @media (orientation: landscape) {
    margin: 0 auto 1.2rem;
  }
}

.swiper-pagination-bullet-active {
  background: $red;
}

.swiper-pagination-bullet {
  margin: 0.25rem;
}

.swiper-pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -1.75rem;
  z-index: 0;
  @media (orientation: landscape) {
    bottom: -1.05rem;
  }
}
