.horse-sorting {
  width: 100%;

  &--wrapper {
    background-color: $tint;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    padding: 0 0 .75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-up(md) {
      padding: .25rem .375rem .25rem 0;
    }

    &.with-transfer-process{
      padding-right: 44px;
    }
  }

  &.unactive--sorting {
    .horse-sorting--item {
      &:after {
        display: none;
      }
    }
  }

  &.free-stable-sorting {
    .horse-sorting--wrapper {
      padding-right: 2.75rem;

      @include media-breakpoint-down(sm) {
        padding: .375rem 2.75rem .375rem 0;
      }

    }

  }

  &--item {
    background: none;
    border: none;
    line-height: 1;
    margin: 0;
    min-width: 4rem;
    position: relative;
    text-transform: capitalize;
    padding: .25rem .625rem;
    font: normal rem-calc(10px) $font-primary;
    color: $gray-500;

    @include media-breakpoint-up(md) {
      padding: .25rem 1rem;
      font-size: .75rem;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: inline-block;
      bottom: -.25rem;
      width: 0;
      height: 0;
      border-left: rem-calc(5px) solid transparent;
      border-right: rem-calc(5px) solid transparent;
      border-top: .375rem solid $theme-gray;
      border-radius: 0.25rem;
      transition: 0.3s;

      @include media-breakpoint-up(md) {
        left: auto;
        bottom: auto;
        top: 50%;
        right: .125rem;
        transform: translateY(-50%);
      }

    }
    &.is-active,
    &:active {
      outline: none;
      &:after {
        border-top-color: $theme-primary;
      }
    }
    &.is-reversed {
      &:after {
        transform: rotate(180deg) translateX(50%);

        @include media-breakpoint-up(md) {
          transform: rotate(180deg) translateY(50%);
        }

      }
    }
    &:focus {
      outline: none;
      &:after {
        border-top-color: $theme-primary;
      }
    }
  }
}
