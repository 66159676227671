.silk{

  &--area{
    background: $active-box-bg;
    padding: 1.25rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &--control{
    margin-top: 1.5rem;

    &-text{
      font: 0.75rem $font-base;
      color: $theme-dark;
      margin-bottom: 0.75rem;
    }

    &-section{
      margin-bottom: 1rem;
      border: 1px solid $theme-light-gray;
    }

    &-button{
      padding: 0.875rem 1rem;
      border-bottom: 1px solid $theme-light-gray;
      border-radius: 3px;
      width: 100%;
      text-align: left;
      position: relative;
      text-transform: capitalize;

      &:before{
        content: " ";
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        background: url('../../images/arrow-right-red.svg') no-repeat;
        background-size: cover;
        position: absolute;
        right: 0.875rem;
        top: 50%;
        margin-top: -0.625rem;
        transform: rotate(-90deg);
        transition: transform 0.5s ease-in-out;
      }

      &.collapsed{
        &:before{
          transform: rotate(90deg);
        }
      }
    }

    &-container{
      padding: 0.675rem 1rem 1.25rem;
    }

    &-color-selector{
      display: flex;
      flex-wrap: wrap;

      .box{
        width: rem-calc(30px);
        height: rem-calc(30px);
        margin-right: 1.25rem;
        margin-bottom: 0.5rem;
        cursor: pointer;
        border: 0.5px solid $black;

        @include media-breakpoint-up(md){
          &:nth-child(7n) {
            margin-right: 0;
          }
        }
      }
    }

    &-pattern-selector{
      margin: 1.25rem 0;

      button{
        width: 100%;
        padding: 0.75rem 1rem;
        font: 1rem $font-base;
        background: none;
        border: 1px solid $theme-light-gray !important;
        border-radius: 3px;
        color: $black !important;
        text-align: left;
        background: transparent !important;

        &:hover, &:focus{
          background: transparent !important;
          color: $black !important;
          border-color: $theme-light-gray !important;
          border-width: 1px;
        }
      }

      .simple-bar{
        max-height: rem-calc(250px);
      }

      .dropdown-toggle{

        &:before{
          content: "";
          display: block;
          width: 1.25rem;
          height: 1.25rem;
          background: url("../../images/arrow-right-red.svg") no-repeat;
          background-size: cover;
          position: absolute;
          right: 0.875rem;
          top: 50%;
          margin-top: -0.625rem;
          transform: rotate(90deg);
        }

        &:after{
          display: none;
        }
      }

      .dropdown-menu{
        width: 100%;
      }
    }
  }

  &--buttons{

    @include media-breakpoint-down(sm){
      display: flex;
    }

    @include media-breakpoint-down(xs){
      flex-wrap: wrap;
    }

    .pick-for-me{
      &:hover{
        background: transparent !important;
        color: $theme-dark !important;
      }
    }

    button{
      width: 100%;

      &:first-child{
        margin-bottom: 1rem;
      }

      @include media-breakpoint-down(sm){
       flex-grow: 1;

        &:first-child{
          margin-right: 0.675rem;
          margin-bottom: 0;
        }

        &:last-child{
          margin-left: 0.675rem;
        }
      }

      @include media-breakpoint-down(xs){
        flex-grow: 0;

        &:first-child{
          margin-right: 0;
          margin-bottom: 1rem;
        }

        &:last-child{
          margin-left: 0;
        }
      }
    }
  }
}
