.my-stables{
  margin-bottom: 2rem;

  .my-horses-list{
    max-height: 70vh;

    @include media-breakpoint-down(xs){
      padding-bottom: 2rem;
    }
  }
}

.stable-details{

  @include media-breakpoint-down(xs){
    margin-top: 0;
  }

  .widget-participans_filter {
    grid-template-columns: auto 4rem;
    border-top: 1px solid $theme-light-gray;
    padding: 0.5rem 2.75rem 0.5rem 0.625rem;

    @include media-breakpoint-down(xs) {
      padding-right: rem-calc(24px);
    }

    .total {
      min-width: 4rem;
    }

  }

  &_header{
    background: $white;
    display: flex;
    position: relative;
    padding-left: 2.75rem;
    margin-bottom: 0.5rem;
    border-top: 1px solid $theme-light-gray;
    border-bottom: 1px solid $theme-light-gray;

    &-back{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 2.75rem;
      background: url('../../images/arrow-right-red.svg') no-repeat center;
      background-size: 1.25rem 1.25rem;
      transform: rotate(180deg);
    }

    &-text{
      padding: rem-calc(15px) 1.875rem;
      border-left: 1px solid $theme-light-gray;
      font-size: 1.125rem;
      line-height: 1;
      text-align: center;

      @include media-breakpoint-down(sm){
        padding: 1.25rem 1.125rem;
        font-size: .875rem;
        line-height: 1.1;
      }
    }
  }

  &_body{
    .widget-user-stable{
      border-bottom: 0.675rem solid $theme-primary;
    }

    &--stable-info{
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      padding: 1rem 0.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .silk{
        padding-right: 0.75rem;

        img{
          width: rem-calc(45px);
          height: rem-calc(32px);

          @include media-breakpoint-down(sm){
            height: 1.5rem;
            width: rem-calc(34px);
          }
        }
      }

      .text{
        font: 400 rem-calc(28px) $font-base;
        color: $theme-dark;

        @include media-breakpoint-down(xs) {
          font-size: 1rem;
        }
      }

      .stable-name{
        font: rem-calc(28px) $font-base;
        color: $theme-dark;
      }

      .stable-type{
        font: rem-calc(28px) $font-base;
        color: $theme-dark;
        text-transform: capitalize;

        @include media-breakpoint-down(xs) {
          font-size: 1rem;
        }
      }
    }
  }

  .user-stable-list_item{
    margin-bottom: 0.5rem;
    padding-right: 0;

    &--link{
      display: none;
    }
  }

  .simple-bar-custom{
    max-height: calc(100vh - 100px);

    @include media-breakpoint-down(sm) {
      max-height: calc(100vh - 50px);
    }
  }

  &_info{
    border-bottom: 0.675rem solid $theme-primary;

    @include media-breakpoint-down(sm){
      border-bottom: 0;
      background: $theme-primary;
      padding: 1.25rem;
    }
  }

  &.stable-details--season{
    .simple-bar-custom{
      max-height: 80vh;

      @include media-breakpoint-down(sm){
        padding-bottom: 2rem;
        max-height: 70vh;
      }
    }
  }

  &.stable-details--leaderboard{
    .simple-bar-custom{
      max-height: 75vh;

      @include media-breakpoint-down(sm){
        padding-bottom: 2rem;
      }
    }
  }

  &--leaderboard{
    .widget-participant.stable-detail{
      .widget-participant_section{
        padding-right: 0rem;
      }
    }
  }

  .user-statistic-card--header {
    background-color: $tint;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    padding: .25rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-statistic-card--item {
      line-height: 1;
      margin: 0;
      min-width: 3rem;
      position: relative;
      text-transform: capitalize;
      padding: .25rem .625rem;
      font: normal rem-calc(10px) $font-primary;
      text-align: center;
      color: $gray-500;

      @include media-breakpoint-up(md) {
        min-width: 4rem;
        font-size: .75rem;
      }

      &-rank {
        min-width: 2.5rem;

        @include media-breakpoint-up(md) {
          min-width: 3.5rem;
        }

      }

      &-stable {

      }

      &-move {

      }

      &-total {

      }

    }

  }

}

.horses-in-stables--sorting {
  .horse-sorting--wrapper{
    padding-right: 2.75rem;

    @include media-breakpoint-up(md) {
      padding-right: 3.125rem;
      padding-left: 3rem;
    }

    @include media-breakpoint-down(xs) {
      padding-right: rem-calc(30px);
    }
  }

  .horse-sorting--item {
    min-width: 3rem;

    @include media-breakpoint-up(md) {
      min-width: 4rem;
      padding: .25rem .625rem;
    }
  }

  .horse-sorting--horse {
    @include media-breakpoint-up(md) {
      padding: .25rem 1rem .25rem .625rem;
    }
  }

}

