.widget-yard {
  display: flex;

  &_silk {
    margin-top: 0.25rem;
    width: 2.5rem;
    height: auto;
  }
  &_main {
    padding-left: 0.5rem;
    display: flex;
    flex: 1;
    flex-grow: 1;
  }

  &_subtitle {
    font: rem-calc(10px) $font-base;
    color: $theme-gray;
  }

  &_name {
    font: rem-calc(20px) $font-base;
    color: $theme-gray;
  }

  &_aside{
    img{
      height: rem-calc(34px);
      width: auto;
    }
  }
}
