.table{


  tr{
    &:nth-child(2n){
      border-top: 1px solid $gray-200;
    }
  }

  th{
    font: $small-font-size $font-base;
    color: $theme-gray;

    &:not(:first-child){
      text-align: center;
    }

    &.head{
      font-size: $paragraph-font-size;
    }
  }

  th, td{
    padding: 0.5rem;
    border: none;
  }

  td{
    font: $small-font-size $font-primary;
    text-align: center;

    &.race-info{
      position: relative;
      &:after{
        content: " ";
        display: block;
        height: 1px;
        width: 100%;
        background: $gray-200;
        position: relative;
        margin: 0 auto;
        bottom: -0.5rem;
      }
    }

    &.points{
      font: 600 1.125rem $font-primary;
      color: $theme-gray;
    }

    &.serie{
      font: $small-font-size $font-primary;
      color: $gray-600;
      text-align: left;
    }

  }
}
