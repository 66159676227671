.perms {

  @include media-breakpoint-down(sm) {
    min-height: 100vh;
  }

  &-content {
    @include media-breakpoint-down(sm) {
      .btn {
        min-width: 100%;
        padding-top: rem-calc(10px);
        padding-bottom: rem-calc(10px);
      }

      .perms-box--delete-btn {
        min-width: auto;
        padding: 0.75rem;
      }
    }
  }

  &--modal {

    @include media-breakpoint-down(sm) {
      .modal-dialog {
        width: 100%;
        max-width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }

      .modal-content {
        height: auto;
        min-height: 100%;
        border-radius: 0;
      }

      .modal-body {
        padding: 0;
      }

      .card-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        box-shadow: 0 0 8px 0 rgba(117, 117, 117, 0.5);
      }

    }

  }

  .dropdown {

    &-toggle {
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 45px;
      padding: 0 rem-calc(10px);
      border: $default-border !important;

      &::after {
        border: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        transform: rotate(180deg);
        width: 2rem;
        background: $tint url("../../images/up-arrow-red.svg") center center no-repeat;
        background-size: rem-calc(14px);
        transition: all .3s;
      }

      &.is-active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &::after {
          transform: rotate(0deg)
        }
      }
    }

    &-menu {
      border-radius: 0 0 3px 3px;
      border: $default-border;
      margin-top: -1px;
      padding: 0;
      width: 100%;
      z-index: 20;
    }

    &-menu--list {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    &-item {
      padding: rem-calc(14px) 1rem;
      color: $filter-color;

      &:hover,
      &:focus,
      &.is-active {
        background: $active-box-bg;
      }
    }

  }

  &--simple-bar {
    max-height: 10rem;
  }

  &-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    padding: rem-calc(14px) 1rem;
    border-top: $default-border;
    border-top: $default-border;
    border-bottom: $default-border;

    &--title {
      font: 1.25rem $font-base;
      color: $theme-gray;
    }

    &--tools {
      font: 1rem $font-base;
      text-align: right;
      color: $theme-primary;
      padding: 0;
    }

  }

  &-intro {

    &--media {
      display: flex;
      align-items: center;
      position: relative;
      padding: 1.25rem 1.5rem;
      background: $white;
      margin-top: 1rem;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      >div {
        width: 50%;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }

    &--image {
      text-align: right;

      @include media-breakpoint-down(sm) {
        width: 100%;
        text-align: center;
      }

      img {
        max-width: 100%;
      }
    }

    &--title {
      font: rem-calc(30px) $font-base;
      color: $theme-dark;
      margin-bottom: 0.75rem;
    }

    &--text {
      font: 0.875rem $font-primary;
      margin-bottom: 1.125rem;
      color: $theme-dark;
      line-height: 1.43;
    }

    &--button {
      padding: 0.675rem 1.5rem;
    }

    &--steps {
      background: $white;
      padding: 1.5rem 1.25rem;
      margin-top: 1rem;

      &-title {
        font: rem-calc(30px) $font-base;
        color: $theme-dark;
        margin-bottom: 1.5rem;

        @include media-breakpoint-down(sm) {
          font-size: 1.25rem;
        }
      }

      .swiper-pagination {
        bottom: 0px;
      }

    }

    &--step {
      @include media-breakpoint-down(sm) {
        padding-bottom: 2rem;
      }

      &-title {
        font: 1rem $font-base;
        color: $red-100;
      }

      &-text {
        font: 0.875rem $font-primary;
        color: $theme-dark;
        line-height: 1.43;
        margin-bottom: 1rem;
      }

      @include media-breakpoint-down(lg) {
        &-image {
          width: 100%;
        }
      }

      @include media-breakpoint-down(sm) {
        &-image {
          width: auto;
        }
      }
    }

    &--faq {
      background: $white;
      padding: 1.5rem 1.25rem 2rem;
      margin-top: 1rem;

      &-title {
        font: rem-calc(30px) $font-base;
        color: $theme-dark;
      }

      &-text {
        font: 0.875rem $font-primary;
        color: $black;
        margin-bottom: rem-calc(30px);
      }

      &-card {
        margin-top: 1.5rem;
        margin-bottom: rem-calc(40px);

        &--title {
          font: 0.875rem $font-primary;
          color: $black;
          margin-bottom: 1.25rem;
          position: relative;
          padding-left: rem-calc(30px);
          width: 60%;
          line-height: 1.57;

          @include media-breakpoint-down(lg) {
            width: 100%;
          }

          .index {
            display: inline-block;
            background: $red-100;
            border-radius: 50%;
            color: $white;
            width: 1.4rem;
            text-align: center;
            position: absolute;
            left: 0;
            top: 0;
          }
        }

        &--image {
          @include media-breakpoint-down(sm) {
            .mobile img {
              max-width: rem-calc(150px);
            }
          }
        }

      }
    }

  }

  .intro--list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 3rem;

    li {
      padding-bottom: .5rem;
    }

    &-num {
      padding-right: .75rem;
    }
  }

  &--form {
    .card {
      margin-bottom: 1rem;
    }

    @include media-breakpoint-down(sm) {
      background-color: $white;
      min-height: calc(100vh - 52px);

      .card {
        border: 0;
        margin-bottom: 0;
      }
    }

    &-error {
      font: 1rem $font-base;
      color: #d20900;
      min-height: 1.5rem;
      margin: 1.5rem 0 rem-calc(30px);
    }

    &-inlist {
      margin-bottom: 10rem;
    }
  }

  &-step {
    &--title {
      font: rem-calc(18px) $font-base;
      color: $black;
      margin-bottom: .25rem;
    }

    &--count {
      color: $red-100;
    }

    &--subtitle {
      font: rem-calc(14px) $font-primary;
      color: #626567;
    }
  }

  &-positions-list {
    display: flex;
    list-style-type: none;
    padding-left: 0;
    margin: 1.5rem 0;

    &--item {
      @include fully-centered;
      width: 3rem;
      height: 3rem;
      border-radius: 3px;
      border: $default-border;
      font: rem-calc(20px) $font-base;
      color: $black;
      cursor: pointer;
      margin-right: rem-calc(14px);

      &.is-selected {
        border: solid 1px $theme-primary;
        background-color: $active-box-bg;
      }

      &.is-disabled {
        opacity: .4;
        color: rgba($black, .4);
        pointer-events: none;
      }
    }
  }

  &-horses {
    @include media-breakpoint-up(md) {
      margin-left: -1rem;
      margin-right: -1rem;
    }

    &--added .box-horse:last-child {
      margin-bottom: 1rem;
    }

    &--dashed {
      margin-bottom: 1rem;
      min-height: 3rem;
      border: dashed 1px $theme-light-gray;

      @include media-breakpoint-up(md) {
        margin: 1rem 1.25rem;
      }

      .widget-participant:last-child .widget-participant_section {
        border-bottom: 0;
      }
    }
  }

  &-box--buttons {
    .btn {
      @include media-breakpoint-up(md) {
        min-width: 16.25rem;
      }
    }

    .btn+.btn {
      margin-left: 1rem;
    }


  }

  &-boxlist {
    @include media-breakpoint-down(sm) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &-box {
    &--name {
      color: $black;
      font-family: $font-primary;
    }

    &--edit {}

    &--position {
      font: 1.5rem $font-base;
      color: $black;
      display: flex;
    }

    &--data {
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;
      @include vertical-center();
      justify-content: space-between;
      border-bottom: $default-border;
    }

    &--counter {
      color: $black;
      font-size: 1rem;
    }

    &--button {
      display: flex;
      padding: rem-calc(10px) 1rem;

      &:hover {
        .icon {
          background-image: url('../../images/add.svg');
        }
      }

      .icon {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0;
        margin-left: auto;

        @include media-breakpoint-up(md) {
          margin-left: 1.25rem;
        }
      }
    }

    &--list {
      display: flex;

      &-positions {
        list-style-type: none;
        padding-left: 0;
        margin: 0;
        display: flex;
        min-width: rem-calc(70px);
        border-right: $default-border;
        flex-direction: column;

        li {
          @include fully-centered;
          flex-grow: 1;
          width: 100%;
          font-size: 1.5rem;
          border-bottom: $default-border;
        }
      }

      &-horses {
        flex-grow: 1;
      }

    }

    &--add {
      border-top: $default-border;
    }

    &--actions {
      padding: rem-calc(20px);

      @include media-breakpoint-down(sm) {
        padding: rem-calc(15px) rem-calc(10px);
      }
    }

    .perms--form-inlist {
      margin-bottom: 0;
    }

  }

  &-control {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    z-index: 20;
    box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.2);

    @include media-breakpoint-down(sm) {
      bottom: rem-calc(50px);
    }

    &.active-box {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &.is-sidebar-opened {
      z-index: 5;
    }

    &--container {
      display: flex;
      min-height: rem-calc(68px);

      @include media-breakpoint-down(sm) {
        min-height: rem-calc(40px);
        padding: 0;
      }

    }

    &--section {
      display: flex;
      flex: 2;
      max-width: 100%;
      flex-wrap: wrap;

      @include media-breakpoint-up(lg) {
        max-width: 70%;
      }
    }

    &--sidebar {
      flex: 1;

      @include media-breakpoint-up(lg) {
        max-width: 30%;
      }
    }

    &--position,
    &--price,
    &--button {
      display: flex;
      font: rem-calc(20px) $font-base;
      color: $theme-gray;

      @include vertical-center;

      @include media-breakpoint-up(lg) {
        padding: 0 .5rem;
      }

      @include media-breakpoint-up(xl) {
        padding: 0 1.4rem;
      }
    }

    &--position {
      border-left: $default-border;

      @include media-breakpoint-down(md) {
        width: 35%;
        justify-content: center;
        border-left: 0;
      }
    }

    &--price {
      border-left: $default-border;
      border-right: $default-border;

      @include media-breakpoint-down(md) {
        width: 65%;
        justify-content: center;
        border-right: 0;
      }

    }

    &--button {
      @include media-breakpoint-down(md) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 1rem;
        border-top: $default-border;
        justify-content: center;
      }

      .btn {
        min-width: rem-calc(280px);
        padding-top: rem-calc(12px);
        padding-bottom: rem-calc(12px);
      }
    }

    &--label {
      display: inline-block;
      padding-right: .25rem;
    }

    &--info {
      font-size: 1rem;
      color: $red-100;
      @include fully-centered;
      height: 100%;
    }

  }

  &-purchase {
    line-height: 1.25;

    &--price {
      color: $black;
      font-family: $font-base;
      font-size: 1rem;
    }

    &--yard {
      border-radius: 3px;
      background-color: $active-box-bg;
      padding: 1rem 1.25rem;
      color: $theme-gray;
      font: 1.25rem $font-base;

      @include media-breakpoint-up(md) {
        padding: 2rem 1rem;
      }
    }

    &--key {
      display: inline-block;
      padding-right: 1rem;
    }
  }

  .fixed-sm {
    @include fixed-block(sm);
  }

  .mobile-controll {
    @include fixed-block(sm);
    @include vertical-center();
    min-height: rem-calc(90px);
  }

  &-backArrow {
    cursor: pointer;
    height: rem-calc(20px);
    display: inline-block;

    &--wrapper {
      min-width: 2.25rem;
    }
  }

}