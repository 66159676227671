/* Vue
-----------------------------------------------------------------*/
[v-cloak] {
  opacity: 0;
}

[v-cloak].v-cloak-hidden {
  display: none;
}

/* Styles
-----------------------------------------------------------------*/
.shadow-block {
  box-shadow: $theme-shadow;
}

.font-weight-demi{
  font-weight: 600 !important;
}


.border-light-top{
  border-top: 1px solid $theme-light-gray;
}

.border-light-right{
  border-right: 1px solid $theme-light-gray;
}

.border-light-bottom{
  border-bottom: 1px solid $theme-light-gray;
}

.border-light-left{
  border-left: 1px solid $theme-light-gray;
}

/* User Logged
-----------------------------------------------------------------*/

.user-is-logged {
  .hide-for-user {
    display: none !important;
  }
}

.user-non-logged {
  .hide-for-guest {
    display: none !important;
  }
}

.overlay-opened {
  position: fixed;
  overflow: hidden;
  height: 100vh;
  width: 100%;

  &.ios-device {
    -webkit-overflow-scrolling: touch;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .main{
    &:before{
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      background: $tint-dark-100;
      position: fixed;
      left: 0;
      top: 0rem;
      right: 0;
      bottom: 0;
      z-index: 4;
    }
  }

  .admin-is-logged{
    .main:before{
      top: 2.4rem;
    }
  }
}

.invisible-input{
  opacity: 0;
}

.flex-1 {
  flex: 1;
}

.flex-grow-1{
  flex-grow: 1 !important;
}

.flex-grow-2{
  flex-grow: 2 !important;
}
