.alert {
  padding: 1rem;
  margin: 0;
  border-radius: 0;
  font: 400 $h5-font-size $font-base;
  line-height: 1.4;

  > div{
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm){
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .text{
    font: 400 1.125rem $font-primary;
    color: $theme-dark;
    margin-bottom: 0;

    @include media-breakpoint-down(sm){
      font-size: 0.75rem;
    }
  }

  span{
    font: 400 1.25rem $font-base;
    color: $theme-dark;
    display: block;
    margin-right: rem-calc(40px);

    @include media-breakpoint-down(sm){
      font-size: 1.125rem;
    }
  }

  &--warning {
    background: $theme-warning;
  }

  &--dark {
    background-color: $theme-dark;
    border-color: $theme-dark;
    color: $white;
  }

  &-small{
    > div{
      flex-direction: column;
      align-items: flex-start;
    }

    .text{
      font-size: 0.75rem;
    }

    span{
      font-size: 1.125rem;
    }
  }
}
