.widget-notification {
  padding: .5rem 1rem;
  background: $white;

  &_title {
    font: 600 $h4-font-size $font-base;
    color: $theme-primary;
    &.is-perms {
      text-align: left;
    }
  }

  &_text {
    font: 400 $h4-font-size $font-base;
    color: $body-color;

    &--sm {
      font: 600 $paragraph-font-size $font-primary;
    }

  }

  &--centered {
    max-width: 375px;
    margin: 0 auto;
  }

  &--shadow {
    box-shadow: $theme-shadow-sm;
  }

}
