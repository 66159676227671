.notification {
  position: absolute;
  top: 0;
  margin: 0 1rem .5rem;
  padding: .625rem;
  border-radius: 3px;
  z-index: 1000;
  width: 50%;
  @include vertical-center;

  @include media-breakpoint-down(lg) {
    width: 75%;
  }

  @include media-breakpoint-down(xs) {
    width: 90%;
  }

  // types
  &-danger {
    background-color: $red-200;
    color: $white;
  }

  // animation styles
  &-enter-active,
  &-leave-active {
    transition: all 1s;
  }
  &-enter {
    opacity: 0;
  }
  &-leave-to {
    opacity: 0;
    position: absolute;
    transform: translateY(-10px);
  }

  //fixed position of notifications
  &-list {
    position: fixed;
    top: 10%;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(md) {
      top: 15%;
      width: 65%;
      left: auto;
      right: auto;
    }

    @include media-breakpoint-only(lg) {
      max-width: 41rem;
    }

    @include media-breakpoint-up(xl) {
      max-width: rem-calc(798px);
    }

    .notification {
      position: relative;
    }

  }

}
