.widget-stable {
  position: relative;
  color: $white;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .375rem .5rem;
    background-color: $theme-dark;
    border-bottom: 1px solid $white;

    .value {
      font: 400 $h3-font-size $font-base;
      position: relative;
      padding-right: rem-calc(34px);

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: rem-calc(30px);
        width: rem-calc(30px);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &--top {
        &:after {
          background-image: url("../../images/leaderboard.svg");
        }
      }

      &--pinned {
        &:after {
          background-image: url("../../images/pin-new.svg");
        }

      }

    }

  }

  &_title {
    font: 400 1.125rem $font-base;
  }

  &_body {
    padding: .5rem 1.5rem .5rem;
    text-align: center;

    .caption {
      font: 400 $h3-font-size $font-primary;
      text-align: center;
      line-height: 1;
      height: 48px;
      overflow: hidden;
    }

    .inner {
      display: flex;
      justify-content: space-between;
    }

    .value {
      font: 600 rem-calc(37px) $font-primary;
      min-height: rem-calc(47px);
    }

    .desc {
      font: 400 $paragraph-font-size $font-primary;

      &--small {
        font-size: $small-font-size;
      }

      &--demi {
        font-weight: 600;
      }

    }

  }

  &_inner {
    background: $theme-secondary url("../../images/stable-bg.svg") no-repeat center center;
    background-size: cover;
  }

  &_footer {
    display: flex;
    justify-content: space-between;
    padding: 0 .5rem .5rem;

    .value {
      font: 600 $h5-font-size $font-primary;
    }

    .desc {
      font: 400 $small-font-size $font-primary;
    }

    .mark {
      display: inline-block;
      vertical-align: sub;
      margin-left: .25rem;
      height: 1.25rem;
      width: 1.25rem;
      background-size: contain;
      transform: rotate(180deg);

      &--increase {
        background-image: url("../../images/up.svg");
      }

      &--decrease {
        background-image: url("../../images/down.svg");
      }
    }
  }
}
