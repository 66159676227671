.horse-stables{
  background: $white;
  border: 1px solid $theme-light-gray;
  padding-top: 1.25rem;
  margin-top: 0.675rem;

  .horse{
    position: relative;

    &--image{
      margin: 0 1rem 1.35rem;

      img{
        height: rem-calc(58px);
        width: 5rem;
      }
    }

    &--name{
      font: 1.5rem $font-base;
      color: $theme-dark;

      @include media-breakpoint-down(xs){
        padding-right: 2.5rem;
      }
    }

    &--info{
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style-type: none;
      margin-right: 2.5rem;

      li{
        font: 0.75rem $font-primary;
        color: $theme-dark;
        padding-right: 0.5rem;
      }
    }

    &--next-race{
      font: 0.75rem $font-primary;
      color: $theme-dark;

      span{
        font-weight: bold;
      }
    }

    &--block{
      border-top: 1px solid $theme-light-gray;
      padding: 0.675rem 1.25rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &--edit{
      display: block;
      width: 1.25rem;
      height: 1.25rem;
      background: url('../../images/pencil.svg') no-repeat center;
      background-size: cover;
      position: absolute;
      right: rem-calc(40px);
      top: 50%;
      margin-top: -0.675rem;
      cursor: pointer;

      @include media-breakpoint-down(xs){
        right: 1rem;
      }
    }
  }
}
