.modal {
  &-backdrop{
    opacity: .5;
  }
  &-content{
    border-radius: rem-calc(3px);
    box-shadow: 0 0 9px 0 $theme-gray;
  }

  &-dialog  {
    max-width: 22.5rem;
    margin: 3.5rem auto 0;
    @include media-breakpoint-up(md) {
      margin-top: 3.5rem;
    }
  }

  &-header,
  &-body {
    padding: 1rem;
  }

  &-title {
    font: rem-calc(20px) $font-base;
    color: $theme-gray;
    padding: 0.5rem 0;
  }

  &-header {
    @include card-header;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .close {
      @include card-close;
    }
  }

  &-body {

    &--content {
      .manage-btn-block {
        text-align: center;
      }
    }

    .modal-body--title{
      font: rem-calc(30px) $font-base;
      color: $theme-gray;
      margin-bottom: 0.75rem;
    }

    .modal-body--subtitle {
      font: rem-calc(20px) $font-base;
      color: $theme-gray;
      margin-bottom: 1.25rem;
    }

    .modal-body--list{
      padding: 0;
      list-style-type: none;

      li{
        font: 1.125rem $font-base;
        color: $black;
      }
    }

    .modal-body--text{
      font: 0.875rem $font-primary;
      color: $theme-gray;
      line-height: 1.43;
      margin-bottom: 1.25rem;
    }

    .modal-body--link{
      display: inline-block;
      font: 1rem $font-base;
      color: $theme-gray;
    }

    .modal-body--account {
      margin-left: -1rem;
      margin-right: -1rem;
      background-color: $tint-200;
      padding: 1rem;
      color: $theme-gray;
      font-family: $font-primary;

      &-heading {
        font-weight: bold;
        font-size: 1rem;
      }

    }

    .modal-body--buttons-line{
      display: flex;

      button, a{
        width: 100%;
        margin: 0 0.675rem;
        flex-grow: 1;

        &:first-child{
          margin-left: 0;
        }

        &:last-child{
          margin-right: 0;
        }
      }
    }

    .simple-bar-create-league {
      max-height: 400px;
      .simplebar-content-wrapper {
        height: auto;
        overflow: hidden scroll !important;
      }
    }

    .transfer-modal-text p {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    .transfer-info-title {
      font-weight: bold;
      font-size: 1.875rem;
    }

    .transfer-info-list {
      padding-left: 1.125rem;
      font-family: $font-primary;
      font-size: rem-calc(15px);
      margin-top: 2rem;
      li {
        padding-bottom: 1rem;
      }
    }

    .transfer-info-danger {
      color: $red-100;
    }

    .stable-item {
      border: 1px solid $theme-primary;
      border-radius: 3px;
      padding: .75rem .625rem;
      font-size: $font-size-base;
      margin-bottom: .5rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: .5rem;
      cursor: pointer;

      &-add {
        position: relative;
        &:before{
          content: " ";
          display: block;
          width: 1.5rem;
          height: 1.5rem;
          background: url('../../images/add-stable-red.svg') no-repeat;
          background-size: cover;
        }
      }
    }
    .stable-name {
      font-size: 1.25rem;
      margin-top: .75rem;
    }
    .finish-league-title {
      font-size: 1rem;
      margin-top: .75rem;
    }
    .league-name {
      font-size: 1.875rem;
      line-height: 1.75rem;
      margin-bottom: 1.5rem;
    }
  }


  &-field {
    border: 0;
    border-bottom: 2px solid $theme-primary;
    width: 100%;
    height: 4rem;
    font-size: 1.125rem;
    &:focus {
      outline: none;
    }
  }

  &-pin {
    background: $active-box-bg;
    padding: 2rem 1rem;
    text-align: center;
    border: 1px solid $theme-primary;
    border-radius: 3px;
    margin-bottom: 1rem;

    .pin-code {
      font: rem-calc(30px) $font-base;
      color: $black;
    }
  }

  &-footer {
    border-top: $default-border;
  }

  &-lg.modal-dialog {
    max-width: rem-calc(320px);
    @include media-breakpoint-up(md) {
      max-width: 26.125rem;
    }
  }

  &-custom-content {
    .modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }

  &-perms{
    .modal-content {
      min-height: 32rem;
    }
  }
}
