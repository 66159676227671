.banner{
    text-align: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

  &--desktop, &--mobile {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &--desktop img, &--mobile img{
        max-width: 100%;
    }
}

.my-stables{
    .banner.stable{
        @include media-breakpoint-up(md){
            display: none;
        }
    }
}

.sidebar{
    .banner.stable{
        @include media-breakpoint-down(sm){
            display: none;
        }
    }
}
