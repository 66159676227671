.filter {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  position: relative;

  // filters block
  &--block {

    &:not(:last-child) {
      flex: 1;
      flex-grow: 1;
      max-width: 25%;
      border-right: 1px solid $filter-border;

      @include media-breakpoint-down(xs){
        max-width: 23%;
      }
    }

    &:last-child {
      flex: 1;
      max-width: 16%;
    }

    &.is-bonus-races {
      max-width: 34%;

      @include media-breakpoint-down(xs){
        max-width: 38%;
      }

      .dropdown-menu {
        width: rem-calc(340px);
      }
      @include media-breakpoint-down(sm) {
        .dropdown {
          position: static;
        }

        .dropdown-menu {
          width: 100%;
        }
      }
    }
  }

  // filters buttons - reset and dropdown
  &--reset,
  .dropdown-toggle {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 rem-calc(10px);
    border-radius: 0;
    border: 0;
    font-family: $font-base;
    font-weight: 400;

    @include media-breakpoint-up(lg) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

  }

  &--reset,
  &--reset:not(:disabled):not(.disabled):hover,
  &--reset:not(:disabled):not(.disabled):active,
  &--reset:not(:disabled):not(.disabled).active {
    color: $theme-primary;
    justify-content: center;
  }

  .dropdown {
    height: 100%;

    &-toggle {
      position: relative;
      overflow: hidden;

      &::after {
        border: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        transform: rotate(180deg);
        width: 2rem;
        background: $tint url("../../images/up-arrow-red.svg") center center no-repeat;
        background-size: rem-calc(14px);
      }
    }

    &-menu {
      border: 0;
      margin-top: 1px;
      border-radius: 0;
      background-color: $filter-background;
      padding: 0;

      @include media-breakpoint-up(sm) {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
      }

    }

    &-item {
      padding: rem-calc(14px) 1rem;
      color: $filter-color;
      border-bottom: solid 1px $filter-border;
      font-family: $font-base;

      &:hover,
      &:focus {
        @include active-filter;
      }

      &.is-active {
        @include active-filter;
      }
    }

  }

  // filters simple bar
  &--simple-bar {
    max-height: 50vh;

    .simplebar-content-wrapper {
      padding-right: 0 !important;
    }
  }

  // filters options list
  &--options-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  // filters result empty
  &-results-is-empty {
    font-size: 2rem;
    margin-top: 2rem;
    text-align: center;
  }

  &-backdrop {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 100vh;
    top: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, .27) 57%, rgba(0, 0, 0, .78) 100%);
    z-index: 1;
  }

}

