.prizes {
  .header {
    margin-bottom: 0;
  }

  .main {
    & > .container {
      max-width: 100%;
      padding: 0;
      flex-direction: column;
    }

  }

  .prizePage {

    &--pageTitle,
    &--pageContent {
      background-color: $white;
      padding-left: 1.25rem;
      padding-right: 1rem;
      border-top: 1px solid $theme-light-gray;
      border-bottom: 1px solid $theme-light-gray;
    }

    &--pageTitle {
      margin-bottom: 10px;
      padding-top: .75rem;
      padding-bottom: .75rem;
    }

    &--pageContent {
      padding-bottom: 3.5rem;

      @include media-breakpoint-up(md) {
        .row {
          .prizePage--column:first-child {
            padding-right: 20px;
          }
          .prizePage--column:last-child:not(:first-child) {
            padding-left: 20px;
          }
        }
      }
    }

    .alert {
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-bottom: 1.25rem;

      @include media-breakpoint-down(md){
        padding-left: 0;
        padding-right: 0;

        .container{
          padding-left: 1rem;
          padding-right: 1rem;
          flex-direction: column;
        }
      }
    }

    &--heading {
      font: rem-calc(20px) $font-base;
      color: #1b2838;
    }

    &--card {
      padding-top: 2rem;

      @include media-breakpoint-up(md) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
      }

    }

    &--monthlyPrizes {
      @include media-breakpoint-up(md) {
        background-color: #fcfcfc;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    &--title {
      font: 400 rem-calc(30px) $font-base;
      margin-top: 2rem;
      margin-bottom: .5rem;
      @include media-breakpoint-up(md) {
        font-size: rem-calc(40px)
      }
      @media (max-width: 345px) {
        font: 400 1.6rem $font-base;
      }
    }

    &--text {
      font: 400 1rem $font-primary;
      margin-bottom: 1.25rem;
      line-height: 1.4;
    }

    &--subtitle {
      font: 400 rem-calc(30px) $font-base;
      color: $theme-gray;
      margin-bottom: rem-calc(10px);
    }

    &--tableTitle {
      font: 600 1rem $font-primary;
      color: $black;
      padding: 1rem;
      @media (max-width: 345px) {
        font: 600 0.8rem $font-primary;
      }
    }

    &--table {
      border: 1px solid $theme-primary;
      border-radius: $border-radius;
      @media (max-width: 345px) {
        overflow: hidden;
      }

      table {
        width: 100%;
        color: $black;
      }

      thead {
        th {
          padding: .75rem 0.4rem;
          font: 600 $small-font-size $font-primary;
          @include media-breakpoint-up(md) {
            padding: .75rem .625rem;
          }
          @media (max-width: 345px) {
            font: 600 10px $font-primary;
            padding: .75rem 0.2rem;
          }

        }
      }

      tbody,
      tfoot {
        tr {
          border-top: 1px solid $theme-primary;
        }

        td {
          padding: .5rem 0.4rem;
          font: 400 $small-font-size $font-primary;
          white-space: nowrap;

          @include media-breakpoint-up(md) {
            padding: .375rem .625rem;
            font-size: 1rem;
          }
          @media (max-width: 345px) {
            font: 400 10px $font-primary;
            padding: .5rem 0.2rem;
          }

        }
      }

      tfoot td {
        font-weight: bold;
      }
    }
  }
}
