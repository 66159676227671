.collapse-block{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background: $white;

  &.collapse-block-modal{

    &.open{
      background: transparent;

      @include media-breakpoint-up(md){
        height: 100vh;
        display: flex;
        align-items: center;
        padding: 2rem 0;

        .collapse-container{
          height: auto;
        }
      }
    }

    > .collapse{
      background: $white;
      min-width: rem-calc(420px);

      @include media-breakpoint-down(sm){
        min-width: auto;
      }

      @include media-breakpoint-up(md){
        max-height: 80vh;
        overflow-y: scroll;
        box-shadow: 0 0 9px 0 $theme-gray;
      }
    }

    @include media-breakpoint-up(md) {
      position: fixed;
      bottom: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25.5rem;
      z-index: 40;
    }
  }

  .collapse{

    &-container{
      height: 100vh;
      @include media-breakpoint-down(sm){
        overflow-y: scroll;
      }

      @include media-breakpoint-up(md) {
        min-height: auto;

      }
    }

    &-body{
      padding: 0.75rem;
    }

    &-header{
      padding: 0.675rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $theme-light-gray;

      h3{
        font: 400 1.25rem $font-base;
        color: $theme-gray;
        margin-bottom: 0;
      }
    }

    &-form{
      margin-top: rem-calc(45px);
      margin-bottom: rem-calc(65px);

      input{
        width: 100%;
        font: 1rem $font-base;
        color: $black;
        border: none;
        border-bottom: 1px solid $theme-primary;
        padding: 0.75rem;

        &.filled{
          border-width: 2px;
        }
      }

      &::placeholder {
        color: $gray-300;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $gray-300;
      }

      &:-ms-input-placeholder { /* Microsoft Edge */
        color: $gray-300;
      }
    }
  }
}

.collapse-silk-builder{
  @include media-breakpoint-down(sm){
    .collapse-body{
      padding-bottom: 4rem;
    }
  }
}

.ios-device{
  .collapse{
    &-container {
      height: 100vh;
    }
  }
}
