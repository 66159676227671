// Fonts
@import "base/fonts";

//Variables
@import "abstracts/variables";

//Libs
@import "libs/bootstrap";
@import "libs/perfect-scrollbar";

//Abstracts
@import "abstracts/functions";
@import "abstracts/mixins";
@import "abstracts/icons";

//Base
@import "base/base";
@import "base/typography";
@import "base/ios11";

//Animations
@import "base/animations";

@import "./modules/buttons";
@import "./modules/card";
@import "./modules/widgets";
@import "./modules/toolbox";
@import "./modules/filter";
@import "./modules/sorting";
// @import "~SonataPageBundle/theme/scss/modules/carousel";
@import "./modules/accordion";
@import "./modules/carousel";
@import "./modules/preloaders";
@import "./modules/tables";
@import "./modules/nav";
@import "./modules/stables-list";
@import "./modules/breadcrumbs";
@import "./modules/menu";
@import "./modules/alert";
@import "./modules/error";
@import "./modules/modal";
@import "./modules/social";
@import "./modules/notification";
@import "./modules/collapse";
@import "./modules/inputs";

//Components
@import "./components/confirmation";
@import "./components/tutorial";
@import "./components/freebet";
@import "./components/game";
@import "./components/leaderboard";
@import "./components/stable-details";
@import "./components/leagues";
@import "./components/searcher";
@import "./components/pick-ten";
@import "./components/perms";
@import "./components/my-stables";
@import "./components/silk";
@import "./components/horse-stables";
@import "./components/top-stables";
@import "./components/perm-list";
@import "./components/winning-stable";
@import "./components/banner";
@import "./components/participant-bet-prompt";
@import "./components/betting-ten";

//Layouts
@import "./layouts/header";
@import "./layouts/heading";
@import "./layouts/sidebar";
@import "./layouts/footer";

//Pages
// UserBundle
@import "~SonataPageBundle/theme/scss/pages/exception";
@import "./pages/homepage";
@import "./pages/my-stables";
@import "./pages/maintenance";
@import "./pages/horse-list";
@import "./pages/prizes";
@import "./pages/help";
@import "./pages/page";
@import "./pages/minigame";

@import "~element-ui/lib/theme-chalk/index.css";
