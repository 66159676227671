.widget-info {
  margin-bottom: .625rem;
  background: $white;
  border-radius: $border-radius-lg;
  overflow: hidden;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .625rem;
    background: $theme-primary;
    font: 400 rem-calc(22px) $font-base;

    @include media-breakpoint-up(md) {
      font-size: $h4-font-size;
    }

    img {
      display: block;
      margin-left: 1rem;
      height: rem-calc(28px);
      width: auto;
    }
  }

  &_title {
    font: 400 rem-calc(31px) $font-base;
    color:  $white;

    &--sm {
      font-size: $h4-font-size;
    }
  }

  &_body {
    padding: .625rem 1rem;

    &--sm {
      padding: .625rem .625rem 1.25rem;
    }
  }

  &_text {
    font: 400 $h5-font-size $font-base;

    &--sm {
      font: 400 rem-calc(15px) $font-primary;
    }

    &--big {
      font-size: rem-calc(60px);
    }
  }
}
