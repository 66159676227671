.freebet{
  background: $tint;
  height: 100%;

  &_header{
    padding: 0.675rem;
  }

  &_title{
    font: $h3-font-size $font-base;
    color: $theme-primary;
    margin-bottom: 0.4rem;
  }

  &_desc{
    font: $h4-font-size $font-base;
    color: $tint-dark;
  }

  &_table{
    text-align: center;
    background: $gray-100;

    tr{
      th{
        border-top: 1px solid $theme-primary;
        border-bottom: 1px solid $theme-primary;
        font: $h3-font-size $font-base;
        color: $theme-primary;

        &:first-child{
          border-right: 1px solid $theme-primary;
        }
      }

      td{

        &:first-child{
          border-right: 1px solid $theme-primary;
        }

        span{
          font: 0.875rem $font-primary;
          color: $tint-dark;
        }
        p{
          font: $h3-font-size $font-base;
          color: $tint-dark;
        }

        .btn{
          padding-left: 0.55rem;
          padding-right: 0.55rem;
        }
      }

      &:last-child{
        td{
          padding-right: 0.4rem;
          padding-left: 0.4rem;
        }
      }
    }
  }
}
