/* ----------------------------------------------------------------
	Variables
-----------------------------------------------------------------*/

/* Project fonts
--------------------------------------------*/
$font-base:           'ToteHeadline', sans-serif;
$font-primary:        'AvantGarde', sans-serif;
$font-secondary:      'Rubik', sans-serif;
$font-tertiary:       'FlamaCond', sans-serif;

$font-family-base:    $font-base;


/* Project Colors
--------------------------------------------*/

$theme-orange:        #ffd900;
$theme-red:           #ff0028;
$theme-red-alt:       #FF2649;
$theme-gray:          #17202a;
$theme-light-gray:    #e5e7e9;
$theme-light-green:   #00be4f;
$theme-green:         #00B248;
$theme-pink:          #ff03d2;
$theme-light-yellow:  #fff3b2;
$theme-marine:        #13EDC9;

//should be removed
$theme-blue:          #00aeef;
$theme-dark-blue:     #0a2334;
$theme-navy-blue:     #0b2a3f;
$blue-100:            #20193D;

$gray-100:            #cccccc;
$gray-200:            #e6e4e4;
$gray-300:            #a6acaf;
$gray-400:            #797D7F;
$gray-500:            #2d3133;
$gray-600:            #17202a;
$gray-700:            #1a1a1a;
$gray-800:            #131313;
$gray-900:            #030303;

$red:                 #ff0028;
$red-100:             #d2001e;
$red-200:             #690026;
$red-300:             #ED1639;
$red-400:             #ff001a;
$red-500:             #d3342d;

// no necessity
$tint:                #ffffff;
$tint-100:            #f2f3f5;
$tint-200:            #f8f8f8;
$tint-300:            #f9f9f9;
$tint-400:            #e9e9e9;
$tint-500:            rgba($tint, .3);
$tint-600:            rgba($tint, .6);
$tint-700:            rgba(254, 254, 254, .6);
$tint-800:            #FCFCFC;


$tint-dark:           $theme-gray;
$tint-dark-100:       rgba($tint-dark, .6);
$tint-dark-200:       rgba($tint-dark, .7);
$tint-dark-300:       rgba($tint-dark, .9);

$theme-primary:       $theme-red;
$theme-secondary:     $red-400;
$theme-dark:          $theme-gray;
$theme-success:       $theme-green;
$theme-danger:        $red;
$theme-warning:       $theme-orange;

$bg-color:            $theme-secondary;
$form-border:         $theme-primary;

$body-bg:             $tint-200;
$body-color:          $theme-gray;

$default-border-color:       $theme-light-gray;
$default-border:             1px solid $default-border-color;
$active-box-bg:              #f2f3f4;

$card-inner-padding:        1rem;

$toolbox-height:             50px;
$filter-color:               #000;
$filter-border:              $default-border-color;
$filter-background:          $tint;
$filter-background-active:   $active-box-bg;


$leader-board-month-filter-bg: #950015;
$private-league-border:        $default-border-color;
$private-league-pin-title:    #727376;

/* Rewrited Bootstrap variables
--------------------------------------------*/
$theme-colors: (
        primary:     $theme-primary,
        secondary:   $theme-secondary,
        dark:        $theme-dark,
        success:     $theme-success,
        danger:      $theme-danger,
);
$colors: (
        "green":     $theme-green,
        "red":       $theme-danger,
);


$theme-shadow:                0 2px 4px 0 rgba(0, 0, 0, 0.5);
$theme-shadow-sm:             0 1px 1px 0 $theme-light-gray;
$theme-shadow-lg:             -2px 6px 12px 0 rgba(109, 101, 101, 0.75);

$main-offset:                 0.625rem;
$grid-gutter-width:           20px;
$header-offset:               76px;

$border-radius:               3px;
$border-radius-sm:            0.125rem;
$border-radius-md:            0.375rem;
$border-radius-lg:            .5rem;
$border-radius-inputs:         .25rem;

/* Typo
--------------------------------------------*/
$font-size-base:              1rem;
$h1-large-font-size:          4.375rem;
$h1-font-size:                2.5rem;
$h2-font-size:                2.25rem;
$h3-large-font-size:          1.875rem;
$h3-font-size:                1.5rem;
$h4-font-size:                1.25rem;
$h5-font-size:                1rem;
$h6-font-size:                0.875rem;
$paragraph-font-size:         0.875rem;
$small-font-size:             0.75rem;
$xs-font-size:                0.625rem;

$input-btn-focus-width:       .1rem;
$input-btn-focus-color:       rgba($theme-primary, .5);
$input-focus-border-color:    rgba($theme-primary, .5);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1000px,
        xl: 1200px
);
//list of login registration fields
$filedNames: email, plainPassword, country_iso_code, firstname, lastname, day;

//list of validation statuses for login registration fields
$fieldStates: pending, active, success, error;

$aside-width: 19.5rem;
