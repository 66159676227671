.help{

	.alert{
		margin-bottom: 1.25rem;

		> div{
			@extend .container;
		}
	}

	.page-title{
		background: $white;
		padding: 0.75rem 1.25rem;
		font: 1.25rem $font-base;
	}

	.tab-content{
		background: $white;
		padding-bottom: 3.5rem;

		.accordion-block{
			margin-bottom: 0rem;
			padding: 0 2.5rem;

			@include media-breakpoint-down(sm){
				padding: 0;
			}


			.shared--title{
				font: 1.5rem $font-base;
				color: $red-100;
				margin-top: 3rem;
				margin-bottom: 2rem;

				@include media-breakpoint-down(sm){
					padding-right: 1rem;
					padding-left: 1rem;
					font-size: 1rem;
				}
			}


			.accordion--title{
				@include media-breakpoint-down(sm){
					font-size: 0.875rem;
				}
			}
		}

	}
}