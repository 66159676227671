.horse-list-page{

  .horse-list {
    &--categories {
      border-top: 1px solid $theme-light-gray;
      border-bottom: 1px solid $theme-light-gray;
    }

    &--heading {
      padding: 0.75rem 0.625rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-up(md) {
        padding: 0.75rem 0 .75rem 1.25rem;
      }

      .title{
        font: 400 1.25rem $font-base;
        color: $black;
        text-transform: initial;

        @include media-breakpoint-down(xs) {
          font-size: 0.875rem;
        }
      }

      .message {
        font: 400 0.625rem $font-primary;
        color: $theme-dark;
        position: relative;

        &:before{
          content: "";
          display: block;
          width: 0.5rem;
          height: 0.5rem;
          background: $theme-pink;
          position: absolute;
          left: -.75rem;
          top: 50%;
          margin-top: -0.25rem;
          border-radius: 50%;
        }
      }
    }

    &--pick-rate {
      align-items: center;
      display: flex;
      justify-content: center;
      min-width: 5.8rem;

      @include media-breakpoint-down(md) {
        min-width: 3.9rem;
      }
    }
  }

  .searcher{
    min-height: 3.125rem;
    border-top: 1px solid $theme-light-gray;
    border-bottom: 1px solid $theme-light-gray;

    @include media-breakpoint-down(sm) {
      min-height: rem-calc(55px);
    }

  }

  .nav-tabs .nav-item{
    margin-bottom: 0;
  }

  .widget-participant.stable-detail{
    .widget-participant_section{
      padding-right: 0;
    }
  }

  .simple-bar-custom{
    max-height: 80vh;

    @include media-breakpoint-down(sm){
      padding-bottom: 2rem;
    }

    @media (max-height: 850px){
      max-height: 78vh;
    }

    @media (max-height: 700px){
      max-height: 74vh;
    }

    @media (max-height: 500px){
      max-height: 50vh;
      padding-bottom: 5rem;
    }
  }

  .horse-sorting--item {
    &-pickRate {
      margin-right: .5rem;
      margin-left: auto;

      @include media-breakpoint-down(xs) {
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
    &-floatPoints {
      min-width: 3.2rem;
    }
  }

  .widget-participant_padding {
    padding-right: 7rem;
  }
}
