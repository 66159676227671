.widget-participans{
  &_filter{
    background: $white;
    display: grid;
    grid-template-columns: auto rem-calc(50px) 2.75rem;
    padding: 0.2rem 3.5rem 0.2rem 0.4rem;
    border-bottom: 1px solid $theme-light-gray;

    &-item {
      padding-bottom: 0.65rem;

      &.is-active{
        span:before{
          border-top: .375rem solid $theme-danger;
        }
      }

      &.is-reversed{
        span:before{
          transform: rotate(180deg);
        }
      }

      span{
        position: relative;

        &:before{
          content: '';
          position: absolute;
          left: 50%;
          margin-left: rem-calc(-2.5px);
          display: inline-block;
          bottom: -0.5rem;
          width: 0;
          height: 0;
          border-left: rem-calc(5px) solid transparent;
          border-right: rem-calc(5px) solid transparent;
          border-top: .375rem solid $theme-gray;
          border-radius: 0.25rem;
          transition: 0.3s;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      padding-right: 2.75rem;
    }

    @include media-breakpoint-down(xs) {
      padding-right: rem-calc(30px);
    }

    > div {
      font: 400 0.75rem $font-primary;
      color: $theme-dark;
    }
  }
}

.widget-participant{
  margin-bottom: 0;
  position: relative;

  .cursor-pointer {
    cursor: pointer;
  }

  &.preseason{
    .widget-participant_races{
      display: none;
    }
  }

  /****Main Styles****/
  &_section{
    background: $tint;
    border-bottom: 1px solid $gray-200;
    padding: 0.5rem rem-calc(10px) .5rem 0;

    @include media-breakpoint-up(md) {
      padding: 0.625rem 1rem 0.625rem 0;
    }

  }

  &_index{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    min-width: rem-calc(45px);
    border-right: 1px solid $theme-light-gray;
    font: 1.25rem $font-base;
    color: $theme-dark;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_absolute{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;

    .widget-participant_icon--add{
      height: 100%;
      background-size: 75%;
      background-position: center;
      margin: 0;
      width: 2rem;
    }
  }

  &_edit, &_link{
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    width: rem-calc(43px);
    cursor: pointer;

    @include media-breakpoint-down(xs) {
      width: rem-calc(30px);
    }
  }

  &_edit{
    background-image: url('../../images/pencil.svg');
  }

  &_link{
    background-image: url('../../images/arrow-right-red.svg');
  }

  &_footer {
    background: $tint;
    border-top: 1px solid $gray-200;
    padding:  0 .5rem;
    color: $theme-gray;
    font: rem-calc(10px) $font-primary;
    line-height: 1.5;
  }

  &_aside {
    display: flex;
    align-items: center;
    @include media-breakpoint-up(md) {
      padding-left: .5rem;
    }
  }

  &_main{
    padding-left: .75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-up(md) {
      padding-left: 1.25rem;
    }

  }

  &_padding{
    padding-right: 4.25rem;
  }

  /*****Participant Details Styles*****/

  &_silk{
    height: rem-calc(25px);
    width: rem-calc(35px);

    @include media-breakpoint-down(sm) {
      height: 1.25rem;
      width: 1.75rem;
    }

    &.silk-lg{
      min-width: 4.25rem;
      height: unset;
    }
  }

  &_silk-sm{
    width: 2.5rem;
  }

  &_name{
    font: 1.125rem $font-base;
    line-height: 1;
    word-break: break-word;
    padding: .75rem 0;

    @include media-breakpoint-down(xs) {
      font-size: 1rem;
      line-height: 1.2;
      padding: .5rem 0;
    }
  }

  &_details{
    display: flex;
    flex-wrap: wrap;
  }

  &_list{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding: 0;

    li{
      font: rem-calc(12px) $font-primary;
      line-height: 1.2;
      margin-top: .25rem;
      margin-right: .75rem;

      @include media-breakpoint-up(md) {
        font-size: rem-calc(14px);
      }
    }

    &--inline{
      display: flex;
      padding-left: 0;
      li{
        white-space: nowrap;
        margin-right: 0.5rem;
      }
    }
  }

  /*****Control Icons Styles*****/

  &_icon{
    width: rem-calc(30px);
    height: rem-calc(30px);
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 0.35rem;
    cursor: pointer;

    @include media-breakpoint-down(sm){
      width: 1.25rem;
      height: 1.25rem;
    }

    &.icon-lg{
      width: 2rem;
      height: 2rem;
    }

    &.icon-sm {
      width: 1.25rem;
      height: 1.25rem;
    }

    &--shortlist{
      background-image: url('../../images/participant-icon/add.svg');
    }

    &--shortlist-active{
      background-image: url('../../images/participant-icon/remove.svg');
    }

    &--add{
      background-image: url('../../images/participant-icon/add.svg');
    }

    &--added{
      background-image: url('../../images/participant-icon/remove.svg');
    }

    &--pin{
      background-image: url('../../images/participant-icon/heart.svg');
    }

    &--pin-active{
      background-image: url('../../images/participant-icon/heart-active.svg');
    }

    &--replace{
      background-image: url('../../images/participant-icon/add.svg');
    }

    &--replace-disabled{
      background-image: url('../../images/participant-icon/remove.svg');
    }

    &--cursor-none{
      cursor: auto;
    }

    &--perms{
      background-image: url('../../images/participant-icon/add.svg');
    }

    &--perms-active{
      background-image: url('../../images/participant-icon/remove.svg');
    }

    &--edit {
      background-image: url('../../images/pencil.svg');
    }

  }

  /*****Points Block Styles*****/

  &_points{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font: 600 1rem $font-primary;
    color: $theme-dark;
    background: $white;
    min-width: 4rem;

    @include media-breakpoint-down(xs) {
      font-size: 0.875rem;
      min-width: 3.2rem;
    }
  }

  &_amount-stables{
    font: 700 1rem $font-primary;
    color: $theme-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.25rem;
    min-width: 3rem;

    @include media-breakpoint-down(xs) {
      font-size: 0.75rem;
      min-width: 2rem;
    }

    @include media-breakpoint-up(md) {
      min-width: 4rem;
    }
  }

  &_indicator{
    width: rem-calc(7px);
    height: rem-calc(7px);
    border-radius: 50%;
    background: $theme-pink;
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;
    z-index: 10;
  }

  /*****Info block Styles*****/
  &_toggler-block{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2.5rem;

    @include media-breakpoint-up(md) {
      min-width: 3rem;
    }

  }

  &_toggler{
    display: block;
    width: 1rem;
    height: 1rem;
    background: url('../../images/arrow-right-red.svg') no-repeat center;
    background-size: 1rem 1rem;
    transform: rotate(90deg);
    transition: transform 0.5s ease-in-out;
    border: none;
    padding: 0;

    &:focus{
      outline: none;
    }

    &[aria-expanded="true"]{
      transform: rotate(-90deg);
    }

    &.rotated {
      transform: rotate(-90deg);
    }
  }

  &_info{
    padding: 0 rem-calc(6px) 0rem 7.5rem;

    @include media-breakpoint-down(sm){
      padding-left: 2rem;
    }

    &-logo{
      padding-top: 1rem;
      text-transform: uppercase;
      font: 400 1rem $font-base;
    }

    &-title{
      font: 600 $small-font-size $font-base;
      color: $theme-gray;
    }
    &-text{
      font: 0.75rem $font-primary;
      color: $theme-gray;
      margin-bottom: 0;
      line-height: 1.6;
      padding-bottom: 1rem;

      @include media-breakpoint-down(sm){
        font-size: 0.625rem;
      }
    }
  }

  &_next-race{
    font: 600 0.625rem $font-primary;
    color: $theme-dark;
    padding: 0.25rem 0.575rem;
    border-right: 1px solid $theme-light-gray;
    margin-right: 0.5rem;
    width: 3.25rem;

    @include media-breakpoint-down(xs) {
      font-size: rem-calc(9px);
    }
  }

  &_next-info{
    font: 600 0.625rem $font-primary;
    color: $theme-dark;

    @include media-breakpoint-down(xs) {
      font-size: rem-calc(9px);
    }
  }

  &_next-race-name{
    font-weight: 400;
  }

  &_next-place{
    font: 400 rem-calc(9px) $font-primary;
    color: $theme-dark;
  }

  &_no-next-info{
    font: 0.75rem $font-primary;
    color: $gray-600;
    margin-top: 0.25rem;
  }

  &--light{
    margin-bottom: 0;
    border-bottom: 1px solid $tint-400;

    @include media-breakpoint-down(sm){
      margin-right: 0;
    }

    .widget-participant{
      &_section{
        border: none;
        padding: 0.3rem 0;

        &:last-child{
          border-bottom: none;
        }
      }

      &_list{
        li {
          font-size: 0.75rem;
        }
      }
    }
  }

  &.stable-detail{

    .widget-participant{

      &_section{
        position: relative;
        padding: 0;
      }
    }
  }

  .sidebar & {
    @include media-breakpoint-down(md) {
      margin: 0 rem-calc(10px);
    }

    .widget-participant_aside {
      @include media-breakpoint-down(md) {
        padding-left: rem-calc(14px);
      }
    }
    .widget-participant_main {
      padding-left: rem-calc(10px);
    }
  }


  &_races{
    background: $white;

    .table {
      th {
        background: $tint-200;
        font: 400 0.75rem $font-base;

        @include media-breakpoint-down(xs) {
          font-size: 0.625rem;
        }
      }

      td {
        font: 400 0.75rem $font-primary;

        @include media-breakpoint-down(xs) {
          font-size: rem-calc(9px);
        }
      }
    }
  }

  &_right-side {
    flex-basis: 30%;
    flex-shrink: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    &>div{
      text-align: center;
    }
  }
}

.widget-participant{
  &.stable-detail.active{
    .widget-participant{
      &_index{
        @include media-breakpoint-down(xs){
          min-width: 1.75rem;
        }
      }

      &-edit{
        width: 2.25rem;
        background-size: 75%;
      }
    }
  }
}
