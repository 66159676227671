.confirmation-stable{
  height: 100%;
  &.is-perms {
    display: flex;
    flex-direction: column;
  }

  &_header{
    margin-top: rem-calc(10px);
    background: $tint;
    padding: 0.675rem;
  }

  &_body {
    background-color: $tint;
    flex-grow: 10;
    padding-top: rem-calc(22px);
  }

  &_title{
    font: $h3-font-size $font-base;
    color: $theme-primary;
    margin-bottom: 0;
  }

  &_list {
    margin: 0;
    padding: 0;
  }

  &_item {
    font-size: rem-calc(20px);
    line-height: 1.2;
    text-align: center;
  }

  &_dot {
    font-size: 1rem;
    line-height: 1;
    text-align: center;
  }

  &_text{
    font: $h4-font-size $font-base;
    color: $tint-dark;
  }

  &_message{
    color: $theme-primary;
    font-size: 1.4rem;
    padding: rem-calc(12px) rem-calc(10px);
  }

  &_text-field{
    position: relative;

    &:before{
      content: " ";
      display: block;
      width: 1.25rem;
      height: 1.15rem;
      background: url('../../images/pencil.svg') no-repeat center;
      background-size: contain;
      position: absolute;
      right: 0.5rem;
      top: 50%;
      margin-top: -0.675rem;
    }

    input{
      padding: 1.125rem 0.875rem;
      border: 2px solid $theme-primary;
      border-radius: rem-calc(6px);
      width: 100%;
    }
  }

  &_stable-control{
    font: $font-size-base $font-base;
    color: $tint-dark;
    text-align: right;
    margin: 0.5rem 0.875rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &_trigger{
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid $tint-dark;
    background: $tint;
    position: relative;
    border-radius: 4px;
    padding: 0;
    margin-left: 0.5rem;

    &:focus{
      outline: none;
    }

    &:before{
      content: " ";
      display: block;
      width: 0.8rem;
      height: 0.5rem;
      background: url('../../images/up-arrow.svg') no-repeat center;
      background-size: cover;
      position: absolute;
      top: 50%;
      margin-top: -0.25rem;
      left: 50%;
      margin-left: -0.4rem;
      transition: transform 0.5s ease-in-out;
      transform: rotate(180deg);
    }

    &.collapsed:before{
      transform: rotate(0deg);
      transition: transform 0.5s ease-in-out;
    }
  }

  &_footer{
    background: $tint;
    height: 100%;
    .is-perms &{
      height: auto;
    }
  }

  &_free-bet{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem 0.3rem 0.4rem 0.55rem;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;

    p{
      font: $small-font-size $font-primary;
      color: $tint-dark;
      margin-bottom: 0;
      margin-right: 0.875rem;
    }

    button{
      white-space: nowrap;
    }
  }

  &_payment{
    padding: 0.75rem 0.675rem;

    &-prize{
      font: 600 $h3-font-size $font-primary;
      color: $tint-dark;
    }

    &-stable{
      font: 600 $small-font-size $font-primary;
      color: $tint-dark;
      margin-left: 0.5rem;
      margin-bottom: 0;
    }
  }

}
