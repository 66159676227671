
.my-stables.pre-season, .pick-ten {
  .horse-grid {
    &--detail {
      display: none;

      .only-list {
        display: none;
      }
    }
  }
}


.horse-grid-template {
  &.stable {
    .horse-grid {
      background: radial-gradient(circle,
        rgba(0, 178, 72, 1) 0%, rgba(0, 178, 72, 1) 40%,
        rgba(0, 190, 79, 1) 40%, rgba(0, 190, 79, 1) 50%,
        rgba(0, 178, 72, 1) 50%, rgba(0, 178, 72, 1) 60%,
        rgba(0, 190, 79, 1) 60%, rgba(0, 190, 79, 1) 70%,
        rgba(0, 178, 72, 1) 70%, rgba(0, 178, 72, 1) 80%,
        rgba(0, 190, 79, 1) 80%, rgba(0, 190, 79, 1) 90%,
        rgba(0, 178, 72, 1) 90%, rgba(0, 178, 72, 1) 100%);
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding-top: rem-calc(80px);
      position: relative;
    
      &:after {
        content: '';
        position: absolute;
        width: 80px;
        height: 20px;
        background-image: url('../../images/pick-ten-mobile-logo.png');
        background-repeat: no-repeat;
        background-position: center;
        bottom: 1rem;
        left: 1rem;
      }
    
      &--item {
        @extend .col-4;
        text-align: center;
        margin-bottom: 1.5rem;
        display: flex;
        justify-content: center;
    
        > div {
          cursor: pointer;
        }
    
        &:nth-child(3n-1), &:last-child {
          transform: translateY(-42px);
        }
    
        img {
          width: auto;
          height: rem-calc(50px);
        }
      }

      &--image {
        display: flex;
        align-items: center;
        justify-content: center;
    
        span {
          padding: 0 0.5rem;
          position: relative;
          display: flex;

          @include media-breakpoint-down(sm) {
            &:before {
              content: " ";
              display: block;
              width: 1.25rem;
              height: 1.25rem;
              background-image: url('../../images/pencil.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-color: $theme-warning;
              border-radius: 50%;
              background-size: 75%;
              position: absolute;
              right: -0.5rem;
              bottom: 0.25rem;
            }
          }
        }
      }

      &--name {
        .empty-horse-stable{
          display: inline;
        }
    
        .empty-horse-list{
          display: none;
        }
      }
    
      &--index {
        display: none;
      }
    
      @include media-breakpoint-down(sm) {
        &--info {
          background: $white;
          border-radius: 3px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        }
    
        &--name {
          border-radius: 3px 3px 0 0;
        }
    
        &--points {
          border-radius: 0 0 3px 3px;
          font: 0.75rem $font-base;
          color: $theme-red;
          background: $tint-200;
          padding: rem-calc(5px);
        }
      }
    }
  }

  &.list {
    @include media-breakpoint-down(sm) {
      .horse-grid {
        &--image {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &--item {
          img {
            height: rem-calc(32px);
          }
        }

        &--detail {
          text-align: left;
        }
      }
    }
  }
}
