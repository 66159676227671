.nav{

  &-item{
    border-right: 1px solid  $theme-light-gray;
    flex-grow: 1;
    text-align: center;

    &.active{
      border-bottom: rem-calc(5px) solid $theme-primary;
    }
  }

  .nav-link{
    border: none;
    font: $font-size-base $font-base;
    color: $tint-dark;
    padding: 0.4rem 0.25rem;
    position: relative;
    &:before{
      content: " ";
      display: block;
      width: 0;
      height: rem-calc(5px);
      background: $theme-primary;
      position: absolute;
      transition: 0.2s;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &:focus{
      outline: none;
    }

    &_title{
      margin-bottom: 0;
      font: 600 $font-size-base $font-primary;
      color: $theme-dark;
    }

    &_count{
      position: absolute;
      font: 600 $small-font-size $font-primary;
      color: $theme-red;
      right: 0.25rem;
      top: 0.25rem;
    }

    &.active{
      &:before{
        width: 100%;
      }
    }
  }

  &-tabs{
    display: flex;
    justify-content: center;
    border: none;

    .nav-item {
      flex: 1;
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }

    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: rem-calc(55px);
      background: $tint;
      padding: 0;
      font: 400 1.125rem $font-base;

      @include media-breakpoint-down(xs){
        font-size: 0.875rem;
        height: rem-calc(55px);
      }

      .value {
        font-size: rem-calc(30px);
      }
    }
  }
}

.filter-nav {

  &-name{
    font: rem-calc(18px) $font-base;
    color: $tint-dark;
    margin-bottom: 0;

    @include media-breakpoint-down(xs){
      font-size: $font-size-base;
    }
  }

  &-count {
    @include media-breakpoint-up(md){
      display: none;
    }
  }

  .nav-item {
    @include media-breakpoint-up(md) {
      border-right: 0;
    }
  }

  .nav-link {
    padding-top: 0.25rem;
    padding-bottom: 0.5rem;

    @include media-breakpoint-up(md){
      align-items: start;
      padding: 0 1rem;

      &::before {
        display: none;
      }
    }
  }

}


.horslist--tabs,
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background-color: $tint;
}

