.social{
  &--title{
    font: 700 1rem $font-base;
    color: #080808;
    margin: 1rem 0;
    text-align: center;
  }

  &--list{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--button{
    width: rem-calc(31px);
    height: rem-calc(31px);
    display: block;
    background: $black;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    cursor: pointer;
    margin: 0 0.325rem;

    &.icon-envelope{
      background-image: url('../../images/social/mail.svg');
    }

    &.icon-commenting-o{
      background-image: url('../../images/social/SMS.svg');
    }

    &.icon-twitter{
      background-image: url('../../images/social/twitter.svg');
    }

    &.icon-whatsapp{
      background-image: url('../../images/social/whatsapp.svg');
    }

    &.icon-facebook{
      background-image: url('../../images/social/facebook.svg');
      background-size: 25%;
    }

    &.icon-copy{
      background-image: url('../../images/social/copy.svg');
      background-size: cover;
      background-color: transparent;
    }

    &.gray{
      background-color: $theme-dark;
    }
  }
}

.tooltip.b-tooltip {
  display: block;
  opacity: .9;
  outline: 0;
}
