.my-stables {
  &.in-season{
    .stables-list{
      .simple-bar-custom{
        @include media-breakpoint-down(sm){
          max-height: 60vh;
          padding-bottom: 3.5rem;

          @media (max-height: 900px) {
            max-height: 55vh;
          }

          @media (max-height: 768px) {
            max-height: 50vh;
          }
        }
      }
    }
  }
}
