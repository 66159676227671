.main-menu {
  .navigation-list{
    display: flex;
    align-items: center;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    li{
      flex-grow: 0;
      border: none;
      position: relative;

      a, span{
        padding: 1rem 2rem;
        display: block;
        color: $black;
        text-transform: capitalize;
        font-family: $font-base;
        font-weight: 400;
        cursor: pointer;

        @include media-breakpoint-down(md){
          padding: 1rem;
        }

        &.active{

          &:before{
            content: "";
            display: block;
            height: rem-calc(5px);
            background: $theme-danger;
            position: absolute;
            bottom: 0;
            left: 1px;
            right: 1px;
          }
        }
      }

      > ul {
        display: none;
      }

      .dropdown-item{
        padding: 0;

        li a.active:before{
          display: none
        }

        .menu-text{
          display: none;
        }

        &:hover{
          background: transparent;
        }
      }

      .dropdown-menu{
        padding: 0;
      }
    }

    > li {
      border-right: 1px solid $theme-light-gray;

      &:first-child{
        border-left: 1px solid $theme-light-gray;
      }
    }

    > .dropdown {
      border-right: 1px solid $theme-light-gray;
      padding-right: 1rem;

      &.active{
        position: relative;

        &:before{
          content: "";
          display: block;
          height: rem-calc(5px);
          background: $theme-danger;
          position: absolute;
          bottom: 0;
          left: 1px;
          right: 1px;
        }
      }
    }

    .dropdown-toggle{
      background: transparent;
      border: none;
      color: $black;
      padding: 1rem 2rem;
      position: relative;
      text-transform: capitalize;
      font-family: $font-base;
      font-weight: 400;

      &:not(.disabled):not(:disabled):hover{
        background-color: transparent;
        border: none;
      }

      &:focus, &:hover{
        color: $black;
      }

      &:after{
        content: "";
        display: block;
        width: 2rem;
        height: 1.1rem;
        background-image: url('../../images/up-arrow-red.svg');
        background-repeat: no-repeat;
        background-size: 0.875rem;
        border: none;
        transform: rotate(180deg);
        position: absolute;
        right: 0.25rem;
        top: 50%;
        margin-top: -0.55rem;
      }
    }

    .dropdown-menu {
      min-width: 11rem;
      border: 1px solid $theme-light-gray;
    }
  }
}
