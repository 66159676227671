@font-face {
  font-family: 'ToteHeadline';
  src: url('../fonts/ToteHeadline/ToteHeadline.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AvantGarde';
  src: url('../fonts/AvantGarde/ITC-Avant-Garde-Gothic-LTBook.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AvantGarde';
  src: url('../fonts/AvantGarde/ITC-Avant-Garde-Gothic-LT-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'FlamaCond';
  src: url('../fonts/FlamaCond/FlamaCond-Bold2.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'FlamaCond';
  src: url('../fonts/FlamaCond/FlamaCond-Basic2.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}


