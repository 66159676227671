.minigame-wrapper {
    .homePage {
        .alert--warning {
            background-color: $theme-marine;
        }

        &--banner {
            &-blue{
                padding: 1.75rem 0;
            }

            &_title {
                font-size: 5.375rem;
                text-transform: uppercase;
                line-height: 75px;
                font-family: $font-tertiary;
                font-weight: bold;
                transform: matrix(0.97, 0, -0.27, 1, 0, 0);


                @media (min-width: 768px) {
                    font-size: 90px;
                }
            }

            &_links {
                margin-bottom: 1.3rem;

                @media (min-width: 768px) {
                    margin-bottom: 2.5rem;
                }
            }
        }

        .steps--inner {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            @media (min-width: 1024px) {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        .card-step {
            width: 100%;
            border: none !important;
            display: flex;
            align-items: center;
            margin: 0;

            img {
                margin: 0 1rem 1rem 0;
            }

            &_text {
                flex: 1;
                text-align: left;
            }
        }

        &--instructions,
        &--sign-up,
        &--feature {
            display: none !important;
        }

        .card--gray {
            @media (min-width: 768px) {
                padding: 0 3.5rem;
            }
        }
    }

    .shared--title,
    .homePage_heading,
    .smart-pick--title {
        font-style: normal;
        font-weight: 600;
        font-size: 70px;
        font-family: $font-tertiary;

        @media (max-width: 768px) {
            font-size: 30px;
            line-height: 37px;
        }
    }
}
