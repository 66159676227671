/* ----------------------------------------------------------------
	Card
-----------------------------------------------------------------*/
.card {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: $white;

  &-header {
    @include card-header;
    position: relative;
  }

  &-footer {
    @include card-footer;
  }

  &-content {
    @include card-content;

    &+.card-content {
      padding-top: 0;
    }
  }

  &-close {
    @include card-close;
    position: absolute;
    right: 1rem;
  }

  &-header,
  &-footer,
  &-content {
    &.no-gutters {
      padding: 0;
    }
  }

  &-bordered {
    border-top: $default-border;
    border-bottom: $default-border;
  }

  &-collapse {
    .card-header {
      cursor: pointer;
      position: relative;
      padding-right: 3rem;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        margin-top: -1rem;
        right: 1rem;
        width: 2rem;
        height: 2rem;
        transform: rotate(0deg);
        background: $tint url("../../images/up-arrow-red.svg") center center no-repeat;
        background-size: 1rem;
        transition: all 0.3s;
      }

      &.collapsed::after {
        transform: rotate(-180deg);
      }
    }
  }

  &--title {
    margin: 0;
    font-size: rem-calc(18px);
  }

  &--question {
    text-align: center;
    font: 500 rem-calc(22px) $font-primary;
    margin-bottom: 2rem;

    @include media-breakpoint-down(md) {
      padding: 0 0.5rem;
    }
  }

  &-perms {
    border-radius: 6px;
    border: $default-border;
    overflow: hidden;

    .card-footer {
      display: block;
    }
  }
}
