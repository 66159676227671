.widget-schedule{
  display: flex;
  align-items: center;

  &_text{
    font: $small-font-size $font-base;
    color: $theme-gray;
    position: relative;
    padding-left: 1rem;
    margin-bottom: 0;

    &:before{
      content: " ";
      display: block;
      width: 0.75rem;
      height: 0.75rem;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &--date{
    .widget-schedule_text:before{
      background-image: url('../../images/participant-icon/date.svg');
    }
  }

  &--location{
    .widget-schedule_text:before{
      background-image: url('../../images/participant-icon/location.svg');
    }
  }

  &--time{
    .widget-schedule_text:before{
      background-image: url('../../images/participant-icon/time.svg');
    }
  }
}
