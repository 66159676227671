$winning-stable-border-color:		#edeef0;

.winning-stable{
	box-shadow: 0 0 6px 0 rgba($black, 0.2);
	border: 1px solid $theme-light-gray;
	margin-bottom: 3rem;
	background: $tint-200;

	&--header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1.25rem 0.675rem;
		background: $white;

		@include media-breakpoint-down(sm){
			margin-bottom: 0.25rem;
			border-bottom: 1px solid $winning-stable-border-color;
		}

		.info{
			display: flex;
			align-items: center;
			.silk {
				margin-right: rem-calc(6px);

				img{
					max-height: rem-calc(37px);
					width: auto;
				}
			}
			.label{
				font: 0.75rem $font-base;
				color: $theme-red;
				margin-bottom: rem-calc(3px);
			}
			.title{
				font: 1.25rem $font-base;
				color: $theme-gray;
			}
			.points{
				font: 1.25rem $font-base;
				color: $theme-gray;
			}
		}
	}

	&--body{
		display: flex;
		flex-wrap: wrap;
		background: $white;

		.horse{
			border-top: 1px solid $winning-stable-border-color;
			border-right: 1px solid $winning-stable-border-color;
			text-align: center;
			width: 20%;

			@include media-breakpoint-down(sm){
				width: 100%;
				display: flex;
				justify-content: space-between;
			}

			&--info-container{
				@include media-breakpoint-down(sm){
					display: flex;
					align-items: center;
				}
			}

			&--points-container{
				@include media-breakpoint-down(sm){
					display: flex;
					align-items: center;
					border-left: 1px solid $winning-stable-border-color;
				}
			}

			&:nth-child(5n) {
				border-right: none;
			}

			&--image {
				padding: 1rem 1rem 0.75rem;
				border-bottom: 1px solid $winning-stable-border-color;

				img{
					max-height: 3rem;
					width: auto;

					@include media-breakpoint-down(sm){
						max-height: rem-calc(29px);
					}
				}
			}

			&--name{
				font: 0.75rem $font-base;
				color: $theme-dark;
				padding: 0.25rem 1rem;
				border-bottom: 1px solid $winning-stable-border-color;

				@include media-breakpoint-down(sm){
					font-size: rem-calc(18px);
					border-bottom: none;
					padding-left: 0;
				}
			}

			&--points{
				font: rem-calc(9px) $font-base;
				color: #182330;
				padding: 0.25rem 1rem;

				@include media-breakpoint-down(sm){
					font-size: 1.125rem;
				}

				span{
					font-weight: bold;
					@include media-breakpoint-down(sm){
						display: none;
					}
				}
			}
		}
	}
}