.maintenance {
  background: $theme-red;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;

  &--text {
    font: rem-calc(30px) $font-primary;
    color: $white;
    line-height: 1.6;
    padding: 0 1.5rem;

    span{
      display: block;
    }
  }
}
