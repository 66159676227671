.betting-ten{
	background: rgba($red, 0.05);
	padding: 0.5rem 0.675rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.25rem;
	position: relative;

	@include media-breakpoint-down(xs){
		padding-left: 1rem;
		padding-right: 1rem;
	}

	&--text{
		font: $white 0.875rem $font-base;
		color: $black;
		line-height: 1.14;
	}

	&--values{
		text-align: center;
		display: flex;
		margin-top: 0.5rem;

		p{
			font: 0.75rem $font-primary;
			color: $black;
			margin-bottom: 0;
		}
	}

	&--close{
		background: transparent url('../images/cross-bold-banner.svg') no-repeat center;
		background-size: cover;
		width: 0.9rem;
		height: 0.9rem;
		position: absolute;
		right: 0.5rem;
		top: 50%;
		margin-top: rem-calc(-6px);
		cursor: pointer;
		padding: 0;
	}
}