.searcher {
  display: flex;
  height: 100%;

  &--search-icon {
    width: 1.25rem;
    height: 1.25rem;
    background: url('../../images/search-icon.svg') no-repeat center center;
    background-size: 1.25rem 1.25rem;
  }

  &--content {
    flex: 1;
    position: relative;
  }

  &--handler {
    width: rem-calc(60px);
    background-color: $white;
    border-left: 1px solid $theme-light-gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &--input {
    border: none;
    border-radius: 0;
    color: $theme-gray;
    padding: 0 2.375rem 0 1.25rem;
    width: 100%;
    height: 100%;
    font: 400 1.125rem $font-base;

    &::placeholder {
      color: $gray-300;
    }

    &:-ms-input-placeholder {
      color: $gray-300;
    }

    &::-ms-input-placeholder {
      color: $gray-300;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 1.125rem;
      font-size: 0.875rem;
    }

    &:focus, &:hover{
      outline: none;
    }

    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  &--reset {
    background: none;
    border: none;
    position: absolute;
    top: 50%;
    right: .75rem;
    transform: translateY(-50%);
    display: inline-block;
    margin: 0;
    padding: 0;
    width: rem-calc(30px);
    height: rem-calc(30px);
    background: url('../../images/close_red.svg') center center no-repeat;
    background-size: 1rem 1rem;
  }

  &--label {
    font: rem-calc(12px) $font-base;
  }

  &--icon {
    max-width: 1.625rem;
    max-height: 1.625rem;
  }

}
