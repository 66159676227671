@import "widgets/widget-stable";
@import "widgets/widget-notification";
@import "widgets/widget-info";
@import "widgets/widget-user-stable";
@import "widgets/widget-participant";
@import "widgets/widget-schedule";
@import "widgets/widget-user-stable-list";
@import "widgets/widget-sort";
@import "widgets/widget-perm";
@import "widgets/widget-modal";
@import "widgets/widget-yard";
@import "widgets/widget-perm-info";
@import "widgets/widget-stable-card";

@media (orientation: landscape) {
  .shortlist-modal-opened{
    padding-bottom: 2.5rem;
  }
}
