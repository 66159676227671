.breadcrumbs{
  background: $tint;
  padding: 0 2.75rem;
  position: relative;
  display: flex;
  border-top: 1px solid $theme-light-gray;
  border-bottom: 1px solid $theme-light-gray;

  @include media-breakpoint-up(md) {
    margin-bottom: .5rem;
  }

  &--back{
    width: 2.75rem;
    padding: 0.75rem 0;
    display: block;
    background: url('../../images/arrow-right-red.svg') no-repeat center;
    background-size: 1.25rem 1.25rem;
    transform: rotate(180deg);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }

  &--item{
    padding: rem-calc(15px) 1.875rem;
    border-left: 1px solid $private-league-border;
    font-size: 1.125rem;
    line-height: 1;
    text-align: center;

    @include media-breakpoint-down(sm){
      padding: 1.25rem 1.125rem;
      font-size: .875rem;
      line-height: 1.1;
    }
  }

  &--invite{
    font: 400 1.125rem $font-base;
    color: $theme-red;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: none;
    border: none;
    padding-right: 2rem;

    &:focus{
      outline: none;
    }

    span{

      &:before{
        content: " ";
        display: block;
        background: url('../../images/add-red.svg') no-repeat;
        background-size: cover;
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        right: 0.25rem;
        top: 0.95rem;
      }
    }
  }
}
