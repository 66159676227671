.heading{
  padding: 1rem;
  background: $tint;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include media-breakpoint-down(xs){
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &_name{
    font: 1.125rem $font-base;
    line-height: 1.2;
    color: $theme-gray;

    @include media-breakpoint-down(xs){
      font-size: 0.875rem;
    }
  }

  &_info{
    list-style: none;
    display: flex;
    text-align: center;
    margin-bottom: 0;

    li{
      padding: 0 0.2rem;
    }

    &-value{
      font: $h3-font-size $font-base;
      color: $theme-gray;
      margin-bottom: -0.675rem
    }

    &-title{
      font: $xs-font-size $font-primary;
      color: $tint-dark
    }
  }

  &_arrow{
    background: url('../../images/arrow-right-red.svg') no-repeat;
    background-size: contain;
    width: 1.25rem;
    height: 1.25rem;
    transform: rotate(180deg);
    margin-right: 1.125rem;
    cursor: pointer;
  }

  &_paddock-button{
    border: none;
    border-left: 1px solid $gray-300;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    padding-left: 0.4rem;
    margin-left: 0.2rem;

    &:hover{
      border-width: 0;
      border-left-width: 1px;
    }

    span{
      font: 0.675rem $font-base;
      color: $theme-red;
    }
  }
}

.heading-desktop {
  padding: 0.5rem;

  @include media-breakpoint-down(md){
    display: none;
  }

  &-title{
    font: $h2-font-size $font-primary;
    color: $theme-primary
  }
}
