.widget-stable-card{
	background: $white;
	border-top: 1px solid $theme-light-gray;

	&:last-child{
		border-bottom: 1px solid $theme-light-gray;
	}

	&_body{
		display: flex;

		&-index{
			width: rem-calc(35px);
			font: 1rem $font-base;
			color: $theme-dark;
			border: 1px solid $theme-light-gray;
			display: flex;
   	 		align-items: center;
    		justify-content: center;
		}

		&-silk{
			
		}
	}
}