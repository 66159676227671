.icon {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .75rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  .icon-sm {
    width: 1.25rem;
    height: 1.25rem;
  }

  &-checked {
    background-image: url('../../images/check.svg');
  }

  &-add {
    background-image: url('../../images/add-red.svg');
  }

  &-edit {
    cursor: pointer;
    background-image: url('../../images/pencil.svg');
  }

}
