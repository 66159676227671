.loading{
  position: relative;
  min-height: rem-calc(75px);

  &:after{
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    background: rgba($tint-dark, 0.15);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &:before{
    content: " ";
    @extend .spinner-border;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1rem;
    margin-left: -1rem;
    z-index: 5;
  }

  &.loading-sm {
    min-height: auto;
  }
}
