.widget-sort{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $tint;
  border: 1px solid $gray-100;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;

  &_title{
    font: $h4-font-size $font-base;
    color: $tint-dark;
  }

  &_types{
    display: flex;
    list-style: none;
    margin: 0;

    li{
      margin-right: 1.75rem;
      font: $font-size-base $font-base;
      text-transform: uppercase;

      button{
        position: relative;
        color: $tint-dark;
        border: none;
        background: none;

        &:focus{
          outline: none;
        }

        &:before{
          content: " ";
          display: block;
          width: 0.9rem;
          height: 0.75rem;
          background: url('../../images/up-arrow.svg') no-repeat;
          transform: rotate(180deg);
          background-size: cover;
          position: absolute;
          right: -1.1rem;
          top: 0.25rem;
          transition: transform 0.3s ease-in-out;
        }
      }

      &.active{
        button:before{
          background-image: url('../../images/up-arrow-red.svg');
        }
      }

      &.reversed{
        button:before{
          transform: rotate(0deg);
        }
      }
    }
  }
}
