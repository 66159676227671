/* ----------------------------------------------------------------
	Buttons
-----------------------------------------------------------------*/
$button-color:              $white;
$button-outline-color:      $body-color;

$button-light-color:        $theme-primary;
$button-toolbox-color:      $theme-dark;
$button-link-color:         $theme-primary;
$button-show-more:          $theme-secondary;
$button-show-pass:          $theme-blue;
$button-show-pass-border:   $form-border;
$btn-white-link-hover:      $theme-secondary;

.btn {
  padding: 1rem;
  font: 600 $h5-font-size $font-primary;
  border-width: 2px;

  &:focus, &:active {
    box-shadow: none !important;
  }

  &:hover {
    border-width: 2px;
  }

  &--sm {
    padding: .625rem 1rem;
  }

  &--xs {
    padding: .625rem 1rem;
    font-size: $small-font-size;
  }

  &--tote {
    font: 400 $h4-font-size $font-base;
    line-height: 1;
  }

  &--block {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  &.btn--color-dark {
    color: $button-outline-color;

    &:not(.disabled):not(:disabled):hover {
      color: $white;
    }

  }

}

.btn-disabled {
  opacity: .65;
  pointer-events: none;
}

.btn-secondary {
  color: $button-color;
  background: $theme-secondary;

  &:not(.disabled):not(:disabled):hover {
    background-color: darken($theme-secondary, 7.5%);
    border-color: darken($theme-secondary, 7.5%);
  }
}

.btn-secondary-outline {
  color: $theme-secondary;
  background: transparent;
  border-color: $theme-secondary;

  &:not(.disabled):not(:disabled):hover {
    background-color: $theme-secondary;
    border-color: $theme-secondary;
    color: $button-color;
  }

}

.btn-primary {
  color: $button-color;
  background: $theme-primary;
  border-color: $theme-primary;

  &:not(.disabled):not(:disabled):hover {
    background-color: darken($theme-primary, 7.5%);
    border-color: darken($theme-primary, 7.5%);
  }
}

.btn-outline-primary{
  color: $theme-gray;

  &.btn-outline-primary-no-hover:hover{
    background: transparent !important;
    color: $theme-gray !important;
  }
}

.btn-primary-outline {
  color: $theme-primary;
  background: transparent;
  border-color: $theme-primary;

  &:not(.disabled):not(:disabled):hover {
    color: $button-color;
    background: $theme-primary;
    border-color: $theme-primary;
  }

}

.btn-toolbox {
  color: $tint;
  background-color: $button-toolbox-color;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media (max-width: 320px) {
    font-size: 0.875rem;
    padding: .75rem 1rem;
  }
  &:hover {
    color: $tint;
  }
}

.btn-dark {
  color: $button-color;
  background: $theme-dark;
  border-color: $theme-dark;

  &:not(.disabled):not(:disabled):hover {
    background-color: darken($theme-dark, 7.5%);
    border-color: darken($theme-dark, 7.5%);
  }
}

.btn-dark-outline {
  color: $theme-dark;
  background: transparent;
  border-color: $theme-dark;

  &:not(.disabled):not(:disabled):hover {
    color: $button-color;
    background: $theme-dark;
    border-color: $theme-dark;
  }
}

.btn-transfer {
  font-size: 1.125rem;
  padding: rem-calc(2px) rem-calc(6px);
  line-height: 1;
  border-radius: $border-radius;
  border: 0;
  margin-right: .675rem;
  color: $tint;
  background: $theme-primary;

  @include media-breakpoint-down(sm) {
    font-size: $small-font-size;
    margin-right: .1rem;
  }
}

.btn-filter {
  padding-left: 1rem;
  padding-right: .625rem;
  @media (min-width: 321px) {
    &:after {
      content: '';
      display: inline-block;
      vertical-align: bottom;
      height: 1rem;
      width: 1rem;
      margin-left: .25rem;
      background: url("../../images/arrow-right.svg") no-repeat center center;
      background-size: contain;
      transition: 0.2s;
    }
    &.opened {
      &:after {
        transform: rotate(90deg);
      }
    }
  }
}

.btn-remove {
  padding: 1.125rem;
  background-image: url("../../images/trash.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.25rem 1.25rem;
}

.btn-visibility {
  padding-left: .75rem;
  padding-right: .75rem;

  background: $black;
  border: 1px solid $white;
  font: 400 $h4-font-size $font-base;
  color: $white;

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: .875rem;
    width: .875rem;
    margin-left: .5rem;
    background: url("../../images/list-open.svg") no-repeat center center;
    background-size: contain;
  }

  &.opened {
    &:after {
      transform: rotate(180deg);
    }
  }

  &:not(.disabled):not(:disabled):hover {
    background: transparent;
    border: 1px solid $white;
    color: $white;
  }

}

.btn-red {
  color: $button-color;
  background: $red-300;
  border-color: $red-300;

  &:not(.disabled):not(:disabled):hover {
    background-color: darken($red-300, 7.5%);
    border-color: darken($red-300, 7.5%);
    color: $button-color;
  }
}

.btn-red-outline {
  color: $body-color;
  background: transparent;
  border-color: $red-300;

  &.btn--xs {
    padding: .375rem .625rem;
  }

  &:not(.disabled):not(:disabled):hover {
    color: $button-color;
    background: $red-300;
    border-color: $red-300;
  }
}



//should be removed
.btn-link {
  color: $button-link-color;

  &:hover,
  &:active,
  &:focus {
    color: darken($button-link-color, 7.5%);
    text-decoration: none;
  }
}

.btn-link--default {
  color: $white;

  &:hover,
  &:active,
  &:focus {
    color: darken($btn-white-link-hover, 7.5%);
    text-decoration: none;
  }
}

.btn-link--secondary {
  color: $theme-secondary;

  &:hover,
  &:active,
  &:focus {
    color: darken($theme-secondary, 7.5%);
    text-decoration: none;
  }
}

.btn-light {
  color: $theme-gray;
  background: $tint;
  border-color: $tint;

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background-color: $tint;
    border-color: $tint;
    color: $theme-gray;
  }
}

.btn-submit {
  width: 100%;
  max-width: 33.75rem;
  margin: auto;
  padding: .8rem 0;
  display: block;
  @include media-breakpoint-down(sm) {
    max-width: 21.75rem;
  }
}

.btn-show-pass-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background: transparent;
    border: 0;
    border-left: 2px solid $button-show-pass-border;
    font-size: $paragraph-font-size;
    font-weight: bold;
    color: $button-show-pass;
    margin: 0;
    padding: 1rem .5rem .9rem .5rem;
    text-align: center;
    min-width: 4.5rem;

    &:focus {
      outline: none;
    }
  }
}

.btn-show-more {
  background: transparent;
  font-family: $font-base;
  font-size: $font-size-base;
  color: $button-show-more;
  margin: 2rem auto 3rem;
  display: block;
}

.btn-modal {
  padding: .8rem 0;
  max-width: 23.75rem;
  width: 100%;
  @include media-breakpoint-down(xs) {
    max-width: 18rem;
  }
}

.btn-close{
  background: url('../../images/close.svg') no-repeat;
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;
}


