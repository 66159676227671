@mixin vertical-center {
  display: flex;
  align-items: center;
}

@mixin fully-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin active-filter {
  position: relative;
  background: $filter-background-active;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: rem-calc(3px);
    background-color: $theme-primary;
  }
}

@mixin card {
  display: flex;
}

@mixin card-header {
  padding: $card-inner-padding;
  font: rem-calc(20px) $font-base;
  color: $theme-gray;
  border-bottom: $default-border;
  display: flex;
  align-items: center;
}

@mixin card-title {
  font: rem-calc(20px) $font-base;
  color: $theme-gray;
  border-bottom: $default-border;
  display: flex;
  align-items: center;
}

@mixin card-content {
  padding: $card-inner-padding;
  flex: 1 1 auto;
}

@mixin card-footer {
  padding: $card-inner-padding;
  border-top: $default-border;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@mixin card-close {
    opacity: 1;
    text-shadow: none;
    color: $white;
    position: relative;
    width: rem-calc(30px);
    height: rem-calc(30px);
    padding: 0;
    margin: 0;

    &:before{
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      background: url('../../images/close.svg') center center no-repeat;
      background-size: 75%;
      position: absolute;
      top: 0;
    }

    &-icon {
      color: $tint;
      position: relative;
      top: -2.5rem;
      font-size: 3.25rem;
      font-weight: 300;
      line-height: 0;
      right: 2px;
    }

    &:focus {
      outline: none;
    }
}

@mixin fixed-block($size) {
  @include media-breakpoint-down($size) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
    width: 100%;
    background: $white;
    padding: 1rem;
    box-shadow: 0 0 8px 0 rgba(117, 117, 117, 0.5);
  }
}

%sidebar-filter-separator {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  height: 0.5rem;
  background: $tint-200;
  left: 0;
  right: 0;
}

