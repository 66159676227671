::placeholder {
  color: $gray-300;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $gray-300;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $gray-300;
}

.styled-input {
  position: relative;
  display: inline-block;
  height: 3rem;
  border: 0;
  border-radius: 0;
  border-bottom: solid 1px $theme-primary;
  color: $theme-gray;
  width: 100%;
  transition: all.3s;

  @include media-breakpoint-up(md) {
    &:not(:focus):valid,
    &:active,
    &:focus,
    &:hover {
      outline: none;
      box-shadow: 0 1px 0 $theme-primary;
    }
  }

}
