.widget-perms {
  color: $body-color;
  display: flex;
  font-size: rem-calc(24px);
  justify-content: space-between;
  padding: rem-calc(12px) 0.5rem;
  span {
    font-size: rem-calc(12px);
  }
}
