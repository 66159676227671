@import "pick-ten/horse-grid-mobile";
@import "pick-ten/horse-grid-desktop";

.pick-ten{

  &-detail{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0.675rem;
    border-top: 1px solid $theme-light-gray;
    border-bottom: 1px solid $theme-light-gray;
    min-height: 5.25rem;
    background: $white;
    margin-bottom: 0.5rem;

    &--image{
      cursor: pointer;

      img{
        height: rem-calc(34px);
        width: 3rem;
      }
    }

    &--info{
      margin-left: 0.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;

      h6{
        font: 0.675rem $font-base;
        color: $gray-400;
        margin-bottom: 0.1rem;

        @include media-breakpoint-up(md){
          font-size: 0.875rem;
          color: #626567;
        }
      }

      input{
        font: 1rem $font-base;
        color: $gray-400;
        margin-bottom: 0;
        border: none;
        padding: 0.25rem 0;
        background: transparent;
        border-bottom: 1px solid $theme-primary;
        width: 13rem;

        &::placeholder {
          color: $theme-dark;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $theme-dark;
        }

        &:-ms-input-placeholder { /* Microsoft Edge */
          color: $theme-dark;
        }

        &:disabled{
          border: none;
          border-bottom: 1px solid $white;
        }

        @include media-breakpoint-up(md){
          font-size: 1.25rem;
          color: $theme-dark;
          width: 15rem;
        }

        @include media-breakpoint-up(lg){
          width: 25rem;
        }
      }

      span{
        display: block;
        padding: 0.25rem 0;
        border-bottom: 1px solid $white;
        font: 1.25rem $font-base;
        color: $theme-dark;
      }
    }

    &--edit{
      width: 1.25rem;
      height: 1.25rem;
      background: url('../../images/pencil.svg') no-repeat;
      background-size: contain;
      display: block;
      border: none;
    }
  }

  &-banner{
    position: relative;
    padding: 0.75rem 1rem;

    &:before{
      content: " ";
      display: block;
      width: rem-calc(30px);
      height: rem-calc(30px);
      background: url('../../images/tote-plus.svg') no-repeat;
      background-size: contain;
      position: absolute;
      right: 1rem;
      top: 50%;
      margin-top: rem-calc(-15px);
    }

    p{
      font: 1rem $font-base;
      color: $theme-gray;
      margin-bottom: 0;
    }
  }

  &-control{
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;

    &.mobile{
      @include media-breakpoint-down(sm){
        display: flex;
      }
    }

    &.desktop{
      @include media-breakpoint-up(md){
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $white;

        .pick-ten-control{
          &--text{
            font: 1.25rem $font-base;
            color: $black;
          }

          &--button{
            font-size: rem-calc(19px);
          }
          &--button-delete {
            margin-left: auto;
            margin-right: 20px;
          }
        }
      }
    }

    &--add{
      flex: 1;
      margin-right: 0.675rem;
    }

    &--stable-done{
      flex: 2;
      margin-left: 0.675rem;
    }
  }

  &-fixed{
    padding: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 8px 0 rgba($gray-400, 0.5);
    background: $white;
    text-align: center;
    z-index: 50;
    display: flex;

    button{
      flex-grow: 1;
      margin: 0 0.675rem;

      &:first-child{
        margin-left: 0;
      }

      &:last-child{
        margin-right: 0;
      }
    }
  }

  .grid-selector{
    display: flex;    
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    .btn {
      height: rem-calc(40px);
      padding: rem-calc(6px);
      width: 29%;
      max-width: 200px;
      margin-right: rem-calc(16px);

      @include media-breakpoint-down(sm) {
        margin-right: (12px);
        margin-top: rem-calc(7px);
        margin-bottom: rem-calc(7px);
        font-size: $paragraph-font-size;
        height: rem-calc(30px);
        padding: rem-calc(4px) rem-calc(6px);
      }
      @media (max-width: 350px) {
        width: 32%;
      }
    }

    .nav-link {
      @include media-breakpoint-down(sm) {
        font-size: $paragraph-font-size;
        padding: 0.84rem 1.5rem;
      }
      @media (max-width: 350px) {
        padding: 0.84rem 0.8rem;
      }
    }
  }

  .stables-list{
    list-style-type: none;
    padding: 0 1rem;
    margin-top: 0.875rem;

    .simple-bar-stable-list{
      max-height: 58vh;
    }

    &--create-stable{
      @include media-breakpoint-up(md){
        color: $black;
        background-color: transparent;
        background-image: none;
        border-color: $theme-primary;
        width: auto;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
      }
    }

    &-item{
      background: $white;
      border-radius: rem-calc(6px);
      padding-top: 1rem ;
      border: 1px solid $theme-light-gray;
      margin-bottom: 0.75rem;

      &--detail{
        display: flex;
        align-items: center;
        padding: 0 1rem 1rem;
        position: relative;
      }

      &--silk{
        margin-right: 1.25rem;

        img{
          width: 4.25rem;
          height: auto;
        }
      }

      &--stable-index{
        font: 0.875rem $font-base;
        color: $gray-300;
      }

      &--stable-name{
        font: 400 1.5rem $font-base;
        color: $theme-dark;
      }

      &--stable-status{
        font: 400 1.25rem $font-base;
        color: $theme-dark;
      }

      &--link{
        position: absolute;
        top: 50%;
        margin-top: -0.562rem;
        right: 1.125rem;

        button{
          width: 1.25rem;
          height: 1.25rem;
          background: url('../../images/arrow-right-red.svg');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &--remove{
        padding: 0.5rem 1rem;
        font: 0.875rem $font-base;
        border-top: 1px solid $theme-light-gray;

        button{
          cursor: pointer;
        }
      }
    }
  }

  .stables-control{
    padding: 0.75rem 1rem;

    &--free-stable-button{
      width: 50%;


      &.show{
        button{
          background: transparent;
          color: $theme-dark;
        }
      }

      .simple-bar-custom{
        height: 15rem;
      }

      button{
        background-color: transparent;
        width: 100%;
        @extend .btn-outline-primary;

        &:hover{
          color: $white;
        }
      }

      .dropdown-toggle::after{
        display: none;
      }

      ul{
        padding: 0;
        width: 100%;

        li{
          padding: 0;
          box-shadow: 0 -1px 1px 0 rgba($black, 0.1);

          a{
            font: 1rem $font-base;
            color: $theme-dark;
            padding: 1.25rem 1rem;
            position: relative;

            &.disabled{
              color: $gray-300;
            }

            &:before{
              content: " ";
              display: block;
              width: 1.25rem;
              height: 1.25rem;
              background: url("../../images/add-red.svg") no-repeat;
              position: absolute;
              right: 0.75rem;
              top: 50%;
              margin-top: -0.625rem;
            }
          }
        }
      }
    }

    > div{
      display: flex;

      button{
        flex-grow: 1;
        margin: 0 0.675rem;

        &:first-child{
          margin-left: 0;
        }

        &:last-child{
          margin-right: 0;
        }
      }
    }

    @include media-breakpoint-down(sm){
      z-index: 5;
      position: fixed;
      bottom: rem-calc(50px);
      left: 0;
      right: 0;
      background: $white;
      box-shadow: 0 0 0.5rem 0 rgba(#757575, 0.5);
    }
  }

  .confirmation{
    background: $white;

    .heading{
      border: 1px solid $filter-background-active;
    }

    &-content{
      padding: 1rem 0.675rem;
    }

    &--title{
      font: rem-calc(30px) $font-base;
      color: $black;
      margin-bottom: 1.25rem;
    }

    &--info{
      background: $filter-background-active;
      padding: 0.875rem 1.25rem;
      margin-bottom: 0.675rem;

      ul{
        list-style-type: none;
        padding: 0;
        margin: 0;

        li{
          font: 1.25rem $font-base;
          color: $black;
        }
      }
    }

    &--text{
      font: 1rem $font-base;
      color: $black;
    }

    &--buttons{
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(sm){
        flex-direction: column;
      }

      button{
        &:first-child{
          margin-right: 0.675rem;

          @include media-breakpoint-down(sm){
            margin-right: 0;
          }
        }

        &:last-child{
          margin-left: 0.675rem;

          @include media-breakpoint-down(sm){
            margin-left: 0;
          }
        }

        @include media-breakpoint-down(sm){
          margin: 0;
          margin-bottom: 0.75rem;
          width: 100%;
        }
      }
    }
  }
}

.horse-grid{
  background: radial-gradient(circle,
          rgba(0,178,72,1) 0%, rgba(0,178,72,1) 40%,
          rgba(0,190,79,1) 40%, rgba(0,190,79,1) 50%,
          rgba(0,178,72,1) 50%, rgba(0,178,72,1) 60%,
          rgba(0,190,79,1) 60%, rgba(0,190,79,1) 70%,
          rgba(0,178,72,1) 70%, rgba(0,178,72,1) 80%,
          rgba(0,190,79,1) 80%, rgba(0,190,79,1) 90%,
          rgba(0,178,72,1) 90%, rgba(0,178,72,1) 100%);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: rem-calc(80px);

    &--item{
        @extend .col-4;
        text-align: center;
        margin-bottom: 1.5rem;
        display: flex;
        justify-content: center;

        > div{
          cursor: pointer;
        }

        &:nth-child(3n-1), &:last-child{
          transform: translateY(-42px);
        }

        img{
          width: auto;
          height: rem-calc(50px);
        }
    }

    &--name{
      background: $white;
      border-radius: 3px;
      font: 0.75rem $font-base;
      color: $theme-gray;
      text-align: center;
      padding: rem-calc(5px);
    }
}
