.widget-user-stable {
  margin-bottom: rem-calc(5px);
  background: $white;

  &.current-user {
    border: 0.25rem solid $theme-primary
  }

  &:first-child {
    margin-top: 1rem;
  }
  &.current-user {
    border: 0.25rem solid $theme-primary;
  }

  &_body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 .625rem 0.2rem 0;
    cursor: pointer;

    .position {
      font: 400 $h2-font-size $font-base;

      &--sm {
        padding: .5rem 0 .125rem;
        font-size: $h3-font-size;

        @include media-breakpoint-up(md) {
          font-size: rem-calc(30px);
        }

      }

    }

    .silk{
      img{
        height: 2.5rem;
        width: auto;
      }
    }

    .name {
      margin-left: .5rem;
      font: 400 1.125rem $font-base;

      &--big {
        @include media-breakpoint-up(md) {
          font-size: $h3-font-size;
        }
      }

    }

    .desc {
      font: 400 rem-calc(11px) $font-primary;
    }

    .value {
      margin-left: .25rem;
      font: 600 rem-calc(21px) $font-base;
    }

    &.active {
      border: .625rem solid $theme-primary;
      margin-bottom: 1rem;
    }

  }

  &_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .125rem .625rem;
    border-top: 1px solid $body-bg;

    .value {
      font: 600 rem-calc(11px) $font-primary;

      &--big {
        font-size: $small-font-size;

        @include media-breakpoint-up(md) {
          font-size: 1.125rem;
        }

      }

    }

    .desc {
      font: 400 $small-font-size $font-primary;

      &--big {
        @include media-breakpoint-up(md) {
          font-size: $h5-font-size;
        }
      }

    }

    .mark {
      display: inline-block;
      vertical-align: sub;
      margin-left: .25rem;
      height: 1rem;
      width: 1rem;
      background-repeat:no-repeat;
      background-position: center center;
      background-size: contain;
      transform: rotate(180deg);
      &--increase {
        background-image: url("../../images/up.svg");
      }

      &--decrease {
        background-image: url("../../images/down.svg");
      }
    }

    .mark-action {
      display: block;
      height: rem-calc(30px);
      width: rem-calc(30px);
      background-repeat:no-repeat;
      background-position: center center;
      background-size: contain;
      cursor: pointer;

      &--active {
        background-image: url("../../images/participant-icon/pin-active.svg");
      }

      &--inactive {
        background-image: url("../../images/participant-icon/pin.svg");
      }

      &.disabled {
        opacity: .5;
        cursor: default;
      }

    }
  }
}

.leagues{
  .widget-user-stable_body{
    padding: 0 .625rem 0.5rem 0;
  }
}
