/* ----------------------------------------------------------------
	Accordion
-----------------------------------------------------------------*/
$accordion-border-color:        $tint-300;
$accordion-border-body-color:   $theme-primary;

.accordion-block{
  margin-bottom: 5rem;

  @include media-breakpoint-down(md){
    margin-bottom: 2.5rem;
  }

  .shared{
    &--title{
      font: rem-calc(30px) $font-base;
      color: $theme-dark;
      margin-bottom: rem-calc(30px);
    }
  }
}

.accordion {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-clip: border-box;
  margin-bottom: 0rem;

  &:first-child{
    border-top: solid 1px #ebedee;
  }

  &--title {
    margin: 0;
    font: 1.25rem $font-base;
  }

  &--header {
    border-bottom: solid 1px #ebedee;
    padding: 1.25rem 1rem;
  }

  &--header[data-toggle="collapse"] {
    position: relative;
    cursor: pointer;
    padding-right: 1.5rem;

    &:after {
      content: "";
      position: absolute;
      right: .7rem;
      top: 50%;
      margin-top: rem-calc(-6px);
      display: block;
      width: 1rem;
      height: 1rem;
      background: url("../../images/arrow-right-red.svg") 0 0 no-repeat;
      background-size: 100%;
      transform: rotate(-90deg);
      transition: all .3s;
    }

    &.collapsed:after {
      transform: rotate(90deg);
    }
  }

  &--body {
    border-top: solid 1px $accordion-border-body-color;
    padding: 1rem;

    p {
      margin: 0;
    }
  }
}
