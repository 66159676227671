.page{
	padding: 1rem;

	h2{
		font: 700 rem-calc(36px) $font-base;
		color: $theme-dark;
	}

	h4{
		font: 1rem $font-base;
		color: black;
	}

	&--list{
		margin: 2rem 0;

		h4{
			font: 700 1.5rem $font-base;
			margin-bottom: 1.25rem;
			color: $red-100;
		}

		ul, ol{

			@include media-breakpoint-down(sm){
				padding-left: 1rem;
			}

			li{
				font: 1rem $font-base;
				color: $theme-dark;
				line-height: 1.75;
			}
		}

	}

	table{
		border: 1px solid $theme-primary;

		tr{
			border-top: 1px solid $theme-primary;

			
			th {
				padding: .75rem .625rem;
			}

			td{
				padding: .375rem .625rem;
				font: 400 1rem $font-primary;
			}
		}
	}

	p{
		font: 1rem $font-base;
		color: $theme-dark;
		line-height: 1.75;
	}

}