.widget-modal{
  max-width: rem-calc(360px);
  background: $tint;
  padding: 3rem 0.75rem 1rem 1.125rem;
  box-shadow: $theme-shadow-sm;
  position: relative;
  width: 100%;

  &_title{
    font: 400 $h4-font-size $font-base;
    color: $theme-primary;
    margin-bottom: 0.5rem;
    text-align: justify;
  }

  &_text{
    font: 600 0.875rem $font-primary;
    color: $tint-dark;
    margin-bottom: 0.25rem;
  }

  &_close{
    height: 2rem;
    width: rem-calc(46px);
    background: url('../../images/close-black.svg') no-repeat center;
    background-size: 50%;
    border: none;
    position: absolute;
    top: .625rem;
    right: 0;

    &:focus{
      outline: none;
    }
  }

  &_overlay{
    position: absolute;
    background: $tint-dark-100;
    height: 100%;
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: rem-calc(54px);
  }

  &_overlay-fixed{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
  }
}
