.horse-grid-template{
  &.stable, &.list{
    .horse-grid {
      @include media-breakpoint-up(md) {
        background: transparent;
        padding-top: 0;

        &--item{

          &:nth-child(3n-1), &:last-child{
            transform: translateY(0);
          }
        }

        &--image{
          padding: 0.5rem 0;
          span:before{
            display: none;
          }
        }

        &--detail{
          display: block;
          border-top: 1px solid $theme-light-gray;
          font: 600 0.75rem $font-primary;
          min-height: rem-calc(32px);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.25rem;

          ul{
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
          }

          span{
            font-weight: 400;
            padding: 0 0.2rem;
          }
        }
      }
    }
  }


  &.stable{
    .horse-grid{
      @include media-breakpoint-up(md){

        &--item{
          display: block;
          flex: 0 0 20%;
          max-width: 20%;
          background: $white;
          padding: 1.5rem 0 0;
          border-left: 1px solid $theme-light-gray;
          border-bottom: 1px solid $theme-light-gray;
        }

        &--name{
          margin-top: 1rem;
          box-shadow: none;
          padding: rem-calc(5px) 0;
          font: 400 1rem $font-base;
          color: $theme-gray;
          border-top: 1px solid $theme-light-gray;

          .index{
            display: inline;
          }
        }
      }
    }
  }

  &.list{
    .horse-grid {
      background: transparent;
      padding-top: 0;

      &--item{
        &:nth-child(3n-1), &:last-child{
          transform: translateY(0);
        }
      }

      &--image{
        padding: 0.5rem 0;
        span:before{
          width: 1.5rem;
          height: 1.5rem;
          background-color: transparent;
          bottom: -0.1rem;
        }
      }

      &--detail{
        display: block;
        border-top: 1px solid $theme-light-gray;
        font: 600 0.75rem $font-primary;
        min-height: rem-calc(32px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.25rem;

        ul{
          list-style-type: none;
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
        }

        li:last-child {
          &:after {
            content: " ";
            display: inline-block;
            width: 1rem;
            height: 1rem;
            margin: 0 .1rem;
            background-image: url('../../images/pencil.svg');
            background-position: center;
            background-size: contain;
            vertical-align: text-top;
          }
        }

        span{
          font-weight: 400;
          padding: 0 0.2rem;
        }
      }

      &--item{
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        background: $white;
        margin-bottom: 0;
        border-bottom: 1px solid $theme-light-gray;
        padding: 0;
      }

      &--info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }

      &--content{
        display: flex;

        &.empty .horse-grid{

          &--detail{
            display: none;
          }
        }
      }

      &--index{
        display: block;
        padding: 0 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid $theme-light-gray;
        margin-right: 0.75rem;
        min-width: 2.75rem;
      }

      &--name{
        text-align: left;

        @include media-breakpoint-up(md) {
          box-shadow: none;
          text-align: left;
          font: 1.25rem $font-base;
          border-bottom: none;
          padding-bottom: 0;
        }

        .empty-horse-stable{
          display: none;
        }

        .empty-horse-list{
          display: inline;
        }
      }

      &--detail{
        padding-top: 0;
        border-top: none;

        ul{
          justify-content: flex-start;
        }

        .only-list{
          display: block;
        }
      }
    }
  }
}

.pick-ten-banner{
  @include media-breakpoint-up(md){
    display: none;
  }
}

.pick-ten{
  .grid-selector{
    @include media-breakpoint-down(sm){
      display: none;
    }
  }
}

.grid-selector{
  background: $white;
  margin-bottom: 0.75rem;
  border-top: 1px solid $theme-light-gray;
  border-bottom: 1px solid $theme-light-gray;

  .nav {
    .nav-link{
      border-right: 1px solid $theme-light-gray;
      padding: 1rem 3rem;

      @include media-breakpoint-down(sm) {
        flex-grow: 1;
        text-align: center;
      }
    }
  }
}

