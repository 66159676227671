.toolbox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;

  @include media-breakpoint-down(md) {
    border-top: solid 1px $filter-border;
  }

  &-block {
    flex: 0 0 100%;
    max-width: 100%;
    height: rem-calc($toolbox-height);
    border-bottom: solid 1px $filter-border;

    @include media-breakpoint-up(md) {
      flex: 0 0 45%;
      max-width: 45%;
      border-top: solid 1px $filter-border;
    }

    &.is-searching {
      @include media-breakpoint-down(sm) {
        height: rem-calc(54px);
      }
    }

    &.is-filtering{
       @include media-breakpoint-up(md) {
        flex: 0 0 55%;
        max-width: 55%;
      }
    }
  }
}
