.footer {

    .game-perms & {
        padding-bottom: 3rem;

        @include media-breakpoint-down(sm) {
            padding-bottom: 6rem;
        }
    }

    &__MainFooter {
        padding-top: 1rem;
        padding-left: 1.125rem;
        padding-right: 1.125rem;
        border-top: 4px solid rgb(255, 0, 40);

        @include media-breakpoint-up(lg) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-header__Header {
        display: flex;
        font-size: 0.75rem;
        -webkit-box-pack: justify;
        justify-content: space-between;
        line-height: 1.2;

        @include media-breakpoint-up(lg) {
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            font-size: 1rem;
            border-bottom: 1px solid rgb(166, 172, 175);
        }
    }

    &-header__DateTime {
        font-family: $font-primary;
        margin-bottom: 0.75rem;

        @include media-breakpoint-up(lg) {
            display: inline;
            margin-right: 1.5rem;
        }
    }

    &-header__Title {
        font-family: $font-primary;
        font-weight: bold;
        margin-left: auto;
    }

    &__FooterItems {
        display: grid;
        grid-template-columns: 1fr;

        @include media-breakpoint-up(lg) {
            gap: 1rem;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &-links__FooterLinksContainer {
        padding-bottom: 1rem;
    }


    &-links__Heading,
    &-images__Heading,
    &-license__Heading {
        font-size: 1rem;
        font-family: $font-base;
        margin-bottom: 2rem;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }

    &-links__Links {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    &-links__LinkStyles,
    &-links__LinkStyles:link,
    &-links__LinkStyles:hover,
    &-links__LinkStyles:active {
        line-height: 1.1;
        color: rgb(255, 0, 40);
        text-decoration: none;
        font-family: $font-primary;
        font-size: 0.75rem;
        padding-bottom: 1rem;
    }


    &-images__Images {
        display: flex;
        flex-wrap: wrap;
        -webkit-box-align: center;
        align-items: center;

        img {
            margin-right: 1.125rem;
            margin-bottom: 1.125rem;
        }
        
        &.logos{
            border-top: 1px solid #e5e7e9;
            border-bottom: 1px solid #e5e7e9;
            padding-top: 1.125rem;
            margin-bottom: 1.125rem;
        }
        
    }

    &-license__FooterLicenseStyles {
        padding-bottom: 4rem;

        @include media-breakpoint-up(lg) {
            padding-bottom: 2rem;
        }
    }

    &-license__LicenseText {
        p {
            margin-bottom: 1em;
        }
        a, p {
            font-family: $font-primary;
            line-height: 1.125rem;
            padding-top: 0px;
            margin-top: 0px;
            font-size: 0.75rem;
        }
    }
}
