.stable-list{
  &_header{
    background: $tint;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.4rem;
    padding: 0 0.675rem;

    .title{
      font: $h4-font-size $font-base;
      color: $theme-gray;
      text-transform: none;
      margin-bottom: 0;
      padding: 0.5rem 0rem 0.5rem;
    }

    .btn-arrow{
      padding: 0;
      width: 1.25rem;
      height: 1rem;
      display: block;
      background: url('../../images/up-arrow-red.svg') no-repeat;
      background-size: cover;
      transform: rotate(180deg);
      transition: transform 0.3s ease-in-out;
    }

    &[aria-expanded="false"] .btn-arrow{
      transform: rotate(0deg);
    }
  }

  &_body{
    .widget-user-stable{
      border-bottom: 1px solid $gray-200;
      margin-bottom: 0;

      &_body{
        .name{
          font-size: 0.875rem;
          margin-top: 0.15rem;
        }
        .position{
          font-size: 1.5rem;
        }
        .desc{
          font-size: rem-calc(9px);
        }
      }

      &_footer{
        justify-content: flex-start;
        border: none;
        padding-left: 0;
      }
    }

    .simple-bar-custom{
      max-height: 45vh !important;
      padding-bottom: 0;

      @include media-breakpoint-down(sm){
        @media (orientation: landscape) {
          max-height: 35vh !important;
        }
      }
    }
  }
}

.stable-slider {
  margin-bottom: 1.3rem;
  @media (orientation: landscape) {
    margin-bottom: 0;
  }
}
