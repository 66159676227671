.side-menu {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  top: 0;
  transition: 0.6s;
  z-index: 6;
  width: $aside-width;
  visibility: hidden;
  z-index: 6;

  &::before{
    content: ' ';
    position: absolute;
    background-color: $tint-dark-200;
    display: inline-block;
    height: 100vh;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.2s;
    transition-delay: 0.2s;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  @include media-breakpoint-up(md) {
    background-color: $tint;
    left: 0;
    transform: translateX(-300%);
  }
  @include media-breakpoint-down(md) {
    background-color: $body-bg;
    right: 0;
    transform: translateX(300%);
  }

  .menu-scrollbar{
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    padding-bottom: 4rem;
    background: $tint;
  }

  &--container{
    display: flex;
    flex-direction: column;
  }

  &--overlay {
    background-color: $tint-dark-200;
    display: inline-block;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  &--logo {
    display: block;
    margin: 1rem 0;
    width: 90%;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  &.is-visible{
    transform: translateX(0);
    transition: 0.2s;
    visibility: visible;
    &:before {
      opacity: 1;
    }
  }
  .side-menu {
    flex-grow: 20;
    background-color: $tint;
    @include media-breakpoint-down(md) {
      background-color: $body-bg;
    }
  }
  &--header {
    flex-grow: 1;
    background-color: $body-bg;
    padding: rem-calc(10px) 0.5rem;
  }
  &--close {
    display: none;
    @include media-breakpoint-down(md) {
      display: inline-block;
      height: rem-calc(28px);
      width: rem-calc(28px);
      background-image: url(../images/close_red.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-color: transparent;
      border: none;
    }
  }
  .side-menu-list {
    color: $body-color;
    font-size: rem-calc(20px);
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      @include media-breakpoint-up(md) {
        &:not(:last-child){
          border-bottom: 1px solid #E9E9E9;
        }
        &:hover {
          .name {
            transition: 0.2s linear;
            transform: translateX(10px);
          }
        }
      }
      .name {
        display: inline-block;
        transition: 0.2s linear;
      }
      @include media-breakpoint-down(md) {
        background-color: $tint;
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
      > a, > span{
        align-items: center;
        color: $body-color;
        display: flex;
        font-size: rem-calc(20px);
        line-height: 1;
        padding: rem-calc(12px) 1rem;
        cursor: pointer;

        &.active {
          position: relative;
          @include media-breakpoint-up(md) {
            &:after {
              content: "";
              border: solid $theme-primary;
              border-width: 0 2px 2px 0;
              display: inline-block;
              padding: 3px;
              position: absolute;
              right: 15px;
              top: 40%;
              transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
            }
          }
          @include media-breakpoint-down(md) {
            border-bottom: 4px solid $red-100;
          }
        }
      }
    }

    img {
      display: inline-block;
      margin-right: 0.5rem;
      height: auto;
      width: rem-calc(25px);
    }
  }
}
