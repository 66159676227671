.user-stables-list{
  margin-top: 0.25rem;

  &.active{
    .user-stable-list{
      &_item{
        padding-right: 2.75rem;
      }
    }
  }

  &_filter {
    display: grid;
    background: $white;
    grid-template-columns: 3.5rem auto rem-calc(44px);
    padding: 0.5rem 2.75rem 0.5rem 0;
    border-top: 1px solid $theme-light-gray;
    border-bottom: 1px solid $theme-light-gray;

    @include media-breakpoint-down(xs) {
      grid-template-columns: rem-calc(45px) auto rem-calc(40px);
    }

    div {
      font: 400 0.75rem $font-primary;

      @include media-breakpoint-down(xs) {
        font-size: 0.675rem;
      }
    }
  }

  .scroll-list{
    max-height: 64vh;

    @include media-breakpoint-down(xs){
      max-height: 66vh;
      padding-bottom: 2rem;
    }
  }
}

.user-stables-list.paid {
  .user-stables-list_filter {
    padding-right: 3.5rem;

    @include media-breakpoint-down(xs) {
      padding-right: 2.6rem;
    }
  }
}

.user-stable-list {

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem .675rem;
    font: 400 1.125rem $font-base;

    @include media-breakpoint-down(xs) {
      font-size: 0.875rem;
    }
  }

  &_item {
    display: flex;
    align-items: stretch;
    background: $white;
    border-bottom: 1px solid $gray-200;
    position: relative;
    padding-right: 2.75rem;

    .cursor-pointer {
      cursor: pointer;
    }

    .title {
      font: 400 1.125rem $font-base;
    }

    &--index{
      min-width: 3.5rem;
      text-align: center;
      font: 1rem $font-base;
      color: $theme-dark;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        font-size: 0.875rem;
        min-width: 2.5rem;
      }
    }

    &--silk{
      padding: 0.5rem 0 0.5rem 0.625rem;
      display: flex;
      align-items: center;
      img{
        height: rem-calc(25px);
        width: rem-calc(35px);

        @include media-breakpoint-down(sm) {
          height: 1.25rem;
          width: 1.75rem;
        }
      }
    }

    &--name{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-family: $font-base;
      line-height: 1.4;
      padding: 0.875rem 1rem;
      word-break: break-word;

      @include media-breakpoint-down(sm){
        padding: 0.75rem .5rem;
        font-size: 0.875rem;
        line-height: 1.2;
      }

      span{
        display: block;
        font: 1rem $font-base;
        color: #7e7e7e;
      }

    }

    &--points{
      min-width: 4rem;
      font-size: 1rem;
      font-family: $font-base;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        min-width: 3rem;
        font-size: 0.875rem;
      }

    }

    &--link{
      background: url('../../images/arrow-right-red.svg') no-repeat center;
      background-size: 1.25rem 1.25rem;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 2.75rem;
      cursor: pointer;
    }

    &--collapse{
      border-left: 1px solid $theme-light-gray;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 2.75rem;
      cursor: pointer;
      display: flex;
      overflow: hidden;

      span{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
        width: 100%;
        background: url('../../images/arrow-right-red.svg') no-repeat center;
        background-size: 60%;
        transition: transform 0.3s ease-in-out;
      }

      &.collapsed{
        span{
          transform: rotate(90deg);
        }
      }

    }

    .mark {
      display: block;
      height: rem-calc(28px);
      width: rem-calc(28px);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      &--pending {
        background-image: url("../../images/notice.svg");
      }

      &--paid {
        background-image: url("../../images/stable.svg");
      }

    }

    &-label{
      margin-top: 0.5rem;

      .user-stable-list_item--name{
        padding-top: 0;
        padding-bottom: 0;
      }
      .user-stable-list_item--label{
        padding-left: 0.875rem;
        font: 0.675rem $font-base;
        color: $gray-300;
      }
    }


    &.season{
      display: flex;
      justify-content: space-between;

      .user-stable-list_item{
        &--total{
          min-width: 4rem;
          font-size: 1rem;
          font-family: $font-base;
          display: flex;
          align-items: center;
          justify-content: center;

          @include media-breakpoint-down(sm) {
            min-width: 3rem;
            font-size: 0.875rem;
          }

        }

        &--moved{
          min-width: 4rem;
          font-size: 1rem;
          font-family: $font-base;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          @include media-breakpoint-down(sm) {
            min-width: 3rem;
            font-size: 0.875rem;
          }

          &:before{
            content: " ";
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: .25rem;

            @include media-breakpoint-up(md) {
              bottom: .5rem;
            }

          }

          &.no_changes{
            &:before{
              display: none;
              content: "";
              width: 6px;
              height: 6px;
              background: $theme-pink;
              border-radius: 50%;
            }
          }

          &.worsened{
            &:before{
              border-left: 3px solid transparent;
              border-right: 3px solid transparent;
              border-top: 6px solid #ff4908;
            }
          }

          &.improved{
            &:before{
              border-left: 3px solid transparent;
              border-right: 3px solid transparent;
              border-bottom: 6px solid #8ac644;
            }
          }
        }
      }
    }
  }
}
