h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $font-primary;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1 { font-size: $h1-font-size; }
h2, .h2 { font-size: $h2-font-size; }
h3, .h3 { font-size: $h3-font-size; }
h4, .h4 { font-size: $h4-font-size; }
h5, .h5 { font-size: $h5-font-size; }
h6, .h6 { font-size: $h6-font-size; }

p {
  font-size: $paragraph-font-size;
  font-weight: 300;
}

small,
.small {
  font-size: $small-font-size;
}

a {
  outline: none;
  &:hover, &:focus {
    text-decoration: none;
  }
}

button{
  background: none;
  border: none;

  &:focus, &:hover{
    outline: none;
  }
}

input{
  &:focus{
    outline: none;
  }
}

/* Titles
-----------------------------------------------------------------*/
.title {
  font-family: $font-primary;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

/* Fonts
-----------------------------------------------------------------*/

.font-base {
  font-family: $font-base;
}

.font-primary {
  font-family: $font-primary;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-extrabold {
  font-weight: 800;
}

.font-heavy {
  font-weight: 900;
}

/* Colors
-----------------------------------------------------------------*/

.color-primary {
  color: $theme-primary;
}

.color-secondary {
  color: $theme-secondary;
}

.color-dark {
  color: $theme-dark;
}
