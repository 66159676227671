.my-stables{
  .nav-tabs{
    justify-content: flex-start;
    margin-bottom: 1rem;
    background: $white;
    border-top: 1px solid $theme-light-gray;
    border-bottom: 1px solid $theme-light-gray;
    margin-bottom: 0;

    .nav-item{
      flex-grow: 0;

      @include media-breakpoint-down(sm){
        flex-grow: 1;
      }

      a{
        padding: 1rem 3rem;
        white-space: nowrap;
        border-bottom: 1px solid $theme-light-gray;
      }
    }
  }

  &--search{
    margin-top: 0.675rem;
    height: rem-calc(50px);
    border-top: 1px solid $theme-light-gray;
    border-bottom: 1px solid $theme-light-gray;

    @include media-breakpoint-down(xs) {
      height: rem-calc(55px);
    }
  }

  &--warning {
    color: $red;
    margin: .5rem auto;
    text-align: justify;

    @include media-breakpoint-down(md) {
      padding: 0 .5rem;
    }
  }
}
