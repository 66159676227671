
.fade-leave-active {
  transition: all 1s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input:focus ~ .floating-label,
input:not(:focus):valid ~ .floating-label {
  top: .25rem;
  left: 0;
  font-size: 9px;
  opacity: 1;
  color: $gray-800;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 1.25rem;
  color: $gray-500;
  font-size: 1.125rem;
  transition: 0.2s ease all;
}
