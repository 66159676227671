/* ----------------------------------------------------------------
	Aside
-----------------------------------------------------------------*/

$aside-width: 19.5rem;

.sidebar{
  margin-left: 1.5rem;
  background: $white;
  right: 0;
  transition: bottom 0.5s ease-in-out, height .5s;

  @include media-breakpoint-up(md) {
    margin-left: 1rem;
    min-height: 100vh;
  }

  @include media-breakpoint-down(sm){
    position: fixed;
    bottom: -100%;
    margin-left: 0;
    z-index: 5;
    width: 100%;
    height: 0;
    border-radius: 0.25rem 0.25rem 0 0;
  }

  &.active{
    @include media-breakpoint-down(sm){
      bottom: 0;
      height: 100vh;
    }

    .admin-is-logged & {
      height: calc(95vh - 40px);
    }
  }

  &-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.675rem;
    padding-right: 0;

    p{
      font: 1.25rem $font-base;
      color: $theme-gray;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md){
      display: none;
    }
  }

  &-close{
    background: url('../../images/close.svg') no-repeat center center;
    background-size: 50%;
    min-width: rem-calc(46px);
    height: 100%;
  }

  &-tab {

    .nav{
      background: $white;
      justify-content: flex-start;
      border-top: 1px solid $gray-200;
      border-bottom: 1px solid $gray-200;

      &-link{
        background: $tint;
        padding-top: rem-calc(26px);
        padding-bottom: rem-calc(26px);
      }
    }
  }

  &-filter{
    margin-bottom: 0.5rem;
    position: relative;
    &:after{
      @extend %sidebar-filter-separator;
      bottom: -0.5rem;
    }

    &:first-child {
     @include media-breakpoint-up(md) {
       margin-top: 0.5rem;
     }

      &:before {
        @extend %sidebar-filter-separator;
        top: -0.5rem;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }

  &-stable-list{
    @include media-breakpoint-down(sm){
      padding-bottom: 2rem;
    }
  }
  .bonus-race-filter {
    .filter--block.is-bonus-races {
      max-width: none;
    }

    .filter--block:last-child {
      max-width: none;
      flex: 0;

      @include media-breakpoint-up(xl) {
        max-width: 17%;
      }
    }
  }

  &-filter .searcher, .filter {
    min-height: rem-calc(55px);
    border-bottom: $default-border;
  }

  .simple-bar-custom.sidebar_scrollbar{
    max-height: 90vh !important;
    padding-bottom: 2rem;

    @include media-breakpoint-down(sm){
      max-height: calc(90vh - 150px) !important;
    }
  }

  @media screen and ( max-height: 556px ){
    .sidebar-close button{
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .nav-tabs .nav-item .nav-link{
      padding-top: 1rem;
      padding-bottom: 1rem;
      height: auto;
    }
  }
}

.ios-device{

  .sidebar {
    transform: translate3d(0,0,0);

    @include media-breakpoint-up(md){
      height: auto;
      min-height: 100vh;
    }

    &.active {
      @include media-breakpoint-down(sm){
        height: 100vh;

        .admin-is-logged & {
          height: calc(85vh - 40px);
        }
      }
    }
  }

  .simple-bar-custom.sidebar_scrollbar{
    @include media-breakpoint-down(sm){
      max-height: calc(95vh - 150px) !important;
    }
  }
}
