.ios11{
    &.page-wrapper{
        min-height: auto;
    }

    .footer-menu{
        bottom: auto;
    }

    .footer{
        display: none;
    }
}