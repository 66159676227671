.leader{
  min-height: 100vh;

  .categories{
    .nav{
      justify-content: flex-start;
      border-bottom: 1px solid $theme-light-gray;

      .nav-item{
        flex: auto;
        flex-grow: initial;
        width: 100%;
        max-width: rem-calc(200px);

        @include media-breakpoint-down(md){
          max-width: none;
          flex-grow: 1;
          flex: 1;
        }
      }
    }
  }

  .nav {
    border-top: 1px solid $theme-light-gray;


    .nav-item{

    }

    .nav-link{
      height: 3.125rem;
      font-size: .875rem;
      font-family: $font-base;

      &_title{
        color: $theme-dark;

        @include media-breakpoint-up(md) {
          font-size: 1.125rem;
        }

      }

      span{
        color: $red-100;
      }
    }
  }

  &-summary{
    padding: 1.4rem 0;
    background: url('../../images/leaderboard-summary.png') no-repeat;
    background-size: cover;
    text-align: center;
    color: $white;
    margin-bottom: 0.5rem;

    @include media-breakpoint-down(xs){
      padding: 1.5rem 0;
      margin-bottom: 0;
    }

    &_title{
      font: 400 rem-calc(30px) $font-base;
    }
  }

  &--search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    margin-top: 0.5rem;
    border-top: 1px solid $theme-light-gray;
    border-bottom: 1px solid $theme-light-gray;

    @include media-breakpoint-down(sm) {
      padding: rem-calc(5px) 0 rem-calc(6px);
    }

    &-my-top-mobile {
      display: none;

      @include media-breakpoint-down(sm) {
        padding: rem-calc(10px);
        display: flex;
        justify-content: flex-end;
        background: $white;
        border-bottom: 1px solid $theme-light-gray;
      }
    }

    &-button-list{
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: stretch;

      li {
        padding: rem-calc(3px) 1rem;
        display: flex;
        align-items: center;

        &.leader--search-my-top-btn {
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        &:not(:first-child) {
          border-left: 1px solid $theme-light-gray;
        }
      }
    }

    &-stable-btn {
      border: 1px solid $red;
      font-size: $paragraph-font-size;
      border-radius: 2rem;
      padding: .25rem .75rem;
    }

    &-input-block{
      position: relative;
      flex-grow: 1;
    }

    &-input{
      border: none;
      border-radius: 0;
      color: $theme-gray;
      padding: 0 2.375rem 0 1.25rem;
      width: 100%;
      height: 100%;
      font: 400 1.125rem $font-base;

      &::placeholder {
        color: $gray-300;
      }

      &:disabled {
        background-color: transparent;
      }

      &:-ms-input-placeholder {
        color: $gray-300;
      }

      &::-ms-input-placeholder {
        color: $gray-300;
      }

      @include media-breakpoint-down(sm) {
        padding-left: 1.125rem;
        font-size: 0.875rem;
      }

      &:focus, &:hover{
        outline: none;
      }

      &::-webkit-search-cancel-button {
        display: none;
      }
    }

    .button-with-icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font: 400 0.75rem $font-base;
      color: $gray-600;

      img{
        width: rem-calc(26px);
        height: rem-calc(26px);
      }
    }
  }

  &--filter{
    display: flex;
    background: $white;
    border-top: 1px solid $theme-light-gray;
    border-bottom: 1px solid $theme-light-gray;
    position: relative;
    padding: 0.35rem 0;

    @include media-breakpoint-down(xs){
      justify-content: space-between;
    }

    .nav{
      justify-content: flex-start;
      border: none;
    }

    .custom-dropdown{

      @include media-breakpoint-down(xs){
        position: static;
      }

      button{
        border: none;
        padding-left: 0.5rem;
        padding-right: 1.75rem;

        &:after{
          right: 0.5rem;
        }
      }

      .dropdown-menu{
        li{
          &.active{
            .dropdown-item{
              background: $active-box-bg;
              position: relative;
              &:before{
                content: " ";
                display: block;
                width: rem-calc(4px);
                height: 100%;
                background: $theme-primary;
                position: absolute;
                left: rem-calc(5px);
                top: 0;
                bottom: 0;
              }
            }
          }
        }
      }
    }

    &-reset-block{
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid $theme-light-gray;
      border-right: 1px solid $theme-light-gray;

      @include media-breakpoint-down(xs) {
        border-right: none;
      }
    }

    &-reset{
      font: 1rem $font-base;
      color: $theme-primary;
      padding: 0 1.125rem;
    }
  }

  .horse-sorting{
    &--wrapper{
      padding: 0 2.75rem .5rem 0;

      @include media-breakpoint-up(md) {
        padding: 3px 3.125rem 3px 0;
      }
    }

    &--item{
      min-width: 3rem;

      @include media-breakpoint-up(md) {
        min-width: 4rem;
        padding: .25rem .625rem;
      }

      &-userStable_name {
        @include media-breakpoint-up(md) {
          padding: .25rem 1rem .25rem .625rem;
        }
      }

      &-position{
        min-width: 2.5rem;
        text-align: center;

        @include media-breakpoint-up(md) {
          min-width: 3.5rem;
        }
      }
    }
  }


  &-month{
    padding: 0.5rem;
    background: $leader-board-month-filter-bg;
  }

  &-list{
      min-height: 5rem;

    .simple-bar-custom{
      max-height: 65vh;

      @include media-breakpoint-down(sm){
        padding-bottom: 3.5rem;
      }
    }
  }
}
