.top-stables{

	.simple-bar-custom{
		min-height: 75vh;
	}

	.stable-list{
		&_header{
			margin-bottom: 0;
			border-bottom: 2px solid $theme-light-gray;

			.title{
				padding: 0.75rem 0rem;
			}
		}

		&_body{
			.user-stable-list_item{
				padding-right: 0;

				&--moved, &--name{
					font: 0.875rem $font-base;
					color: $black;
					border-right: 0;
				}

				&--moved{
					min-width: 3rem;
					border-left: 0;
					&:before{
						margin-bottom: -3px;
					}
				}

        &--rank{
          min-width: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

				&--name{
					padding: 0.75rem 0.5rem;
				}

				&--total{
          min-width: 3.5rem;
					border-left: 0 !important;
					color: $red-100;
				}

				&--link{
					border-left: 0;
					width: 1.75rem;
				}
			}
		}
	}

	.user-stable-titles{
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid $theme-light-gray;

		&--item{
      width: 3.5rem;
			font: 0.675rem $font-primary;
			color: $theme-dark;
			margin-bottom: 0;
			padding: 0.5rem 0.25rem;
			text-align: center;
		}

		&--rank{
			width: 3rem;
		}

		&--total{
			min-width: 3.5rem;
		}

    &--move{
      width: 3rem;
    }
	}
}
