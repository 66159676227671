/* ----------------------------------------------------------------
	Exception page
-----------------------------------------------------------------*/

$exception-btn-color:       $theme-primary;
$exception-number-color:    $theme-primary;


.exception {
  margin-top: 4.5rem;

  @include media-breakpoint-up(md) {
    min-height: 100vh;
    margin-top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &--inner {
    text-align: center;
  }

  &--img {
    width: 16.25rem;
    margin-bottom: 4.25rem;

    @include media-breakpoint-up(md) {
      width: 20rem;
    }
  }

  &--number {
    font-size: 7.5rem;
    font-weight: bold;
    color: $exception-number-color;
    line-height: 1;
    margin-bottom: 0;
  }

  &--type {
    font-size: 2.25rem;
    padding: 0 1rem;
  }

  &--text {
    @include media-breakpoint-down(md) {
      width: 70%;
      margin: auto;
    }
  }

  &--btn {
    margin-top: 3rem;
    color: $exception-btn-color;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  //fix vertical centering main content with IE11
  .exception,
  .maintenance {
    height: 1px;
  }
}
