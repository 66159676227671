.footer-menu{
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $theme-primary;
  z-index: 30;

  &--wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 0.3rem 0.75rem;

    @media (max-width: 350px) {
      padding: 0.3rem 0.35rem;
    }
  }

  &--subname {
    color: $tint;
    font: $xs-font-size $font-base;
    display: inline-block;
    line-height: 1;
  }

  &--toggler {
    background: transparent url('../../images/menu.png') no-repeat center;
    background-size: contain;
    border: none;
    display: inline-block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;

    &:focus{
      outline: none;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 100%;
    }
  }

  .footer-menu-list-container {
    flex-grow: 10;
  }

  .footer-menu-list{
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding: 0;

    li{
      border: none;

      img{
        max-width: rem-calc(28px);
        height: auto;
      }

      a{
        padding: 0.3rem 0.5rem;
        color: $tint;
        font: $xs-font-size $font-base;
        text-transform: none;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        @media (max-width: 350px) {
          padding: 0.3rem 0.25rem;
        }

        &.active{
          position: relative;
          background: $red-100;
        }
      }

      .dropdown-item{
        span > .menu-text{
          display: none;
        }
      }

      span{
        text-align: center;
      }
    }

    .dropdown{
      display: flex;
      align-items: center;
      position: relative;

      &.active{
        position: relative;
        background: $red-100;
      }
    }

    .dropdown-toggle{
      padding: 0;
      font: 0.675rem $font-base;
      border: none;
      min-height: 2.4rem;
      padding-top: rem-calc(27px);
      background: transparent !important;

      &:before{
        content: " ";
        display: block;
        height: 1.75rem;
        background: url('../../images/cursoractive.svg') no-repeat center;
        background-size: contain;
        position: absolute;
        top: 0.2rem;
        left: 0;
        right: 0;
      }

      &:after{
        display: none;
      }

      &:focus, &:hover{
        background: transparent;
      }
    }

    .dropdown-menu{
      background: transparent;
      border: none;

      li{
        padding-bottom: 0.3rem;

        a{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: rem-calc(63px);
          height: rem-calc(63px);
          background: $red-100;
          border-radius: 50%;
          font: 0.875rem $font-base;
          color: $white;
          text-align: center;
          white-space: normal;
          padding: 0 0.2rem;

          span{
            display: none;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    display: none;
  }
}

