/* ----------------------------------------------------------------
	Base
-----------------------------------------------------------------*/

@import "helpers";

/* Main layout styles
-----------------------------------------------------------------*/

.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow-y:auto;
}

.main{
  position: relative;
}

.container{

  @include media-breakpoint-down(md){
    max-width: 100%;
  }

  @include media-breakpoint-down(sm){
    padding-left: 0;
    padding-right: 0;
  }
}

.section{
  flex: 2;
  max-width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 70%;
  }

}

.sidebar{
  flex: 1;

  @include media-breakpoint-up(md) {
    max-width: 30%;
  }
}


.layout-two-column {
  @extend .row;

  .layout--content {
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(8);
    }

  }

  .layout--sidebar {
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
  }
}

/* Extend bootstrap classes
-----------------------------------------------------------------*/

.static-layout-offset {
  @extend .offset-md-2;
  @extend .col-md-8;
  @extend .offset-sm-1;
  @extend .col-sm-10;

}

.container {
  position: relative;
  &.is-main-tabs {
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.simple-bar-custom{
  max-height: 65vh;
}


.scroll-stables.simple-bar-custom {
    max-height: calc(100vh - 162px);

    @include media-breakpoint-up(md) {
      max-height: 100%;
    }
  }


.simple-bar-custom {

  &.scroll-pending {

    @include media-breakpoint-up(md) {
      max-height: calc(100vh - 500px);//468px
    }
  }

  &.scroll-paid {

    @include media-breakpoint-up(md) {
      max-height: calc(100vh - 240px);//204px
    }
  }

  &.scroll-pending-paid {
    @include media-breakpoint-up(md) {
      max-height: calc(50vh - 220px);//207px
    }
  }

  &.scroll-list {
    .ps__rail-y {
      display: block!important;
      opacity: 1;
    }
  }
}

.custom-dropdown{
  position: relative;

  @include media-breakpoint-down(sm){
    width: 100%;
  }

  button{
    width: 100%;
    background: transparent !important;
    border: 1px solid $tint-400;
    font: 1rem $font-base;
    color: $black !important;
    text-align: left;
    position: relative;
    box-shadow: none;

    &:after{
      content: "";
      display: block;
      width: 2rem;
      height: 1.1rem;
      background-image: url("../../images/up-arrow-red.svg");
      background-repeat: no-repeat;
      background-size: 0.875rem;
      border: none;
      transform: rotate(180deg);
      position: absolute;
      right: 1.25rem;
      top: 50%;
      margin-top: -0.55rem;
    }

    &:hover{
      border-width: 1px;
      background: transparent !important;
      border-color: $tint-400 !important;
      color: $black
    }
  }

  .simple-bar-custom{
    max-height: 250px;
  }

  .dropdown-menu{
    width: 100%;
  }

  .dropdown-item{
    padding: 1rem;
    font: 1rem $font-base;
    color: $black;

    &:focus, &:active, &:hover{
      background-color: $white;
    }
  }
}

.custom-el-select{
  width: 100%;

  .el-select__tags{
    width: 100% !important;
  }

  .el-input{
    position: relative;

    &:after{
      content: "";
      display: block;
      width: 2rem;
      height: 1.1rem;
      background-image: url("../../images/up-arrow-red.svg");
      background-repeat: no-repeat;
      background-size: 0.875rem;
      border: none;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      position: absolute;
      right: 0.5rem;
      top: 50%;
      margin-top: -0.55rem;
    }
  }

  .el-input__inner{
    font: 1rem $font-base;
    color: $theme-dark;
    border: none;
    padding-left: 0.5rem;
    padding-right: 1.75rem;

    &:-ms-input-placeholder {
      font: 1rem $font-base;
      color: $theme-dark;
    }

    &::-webkit-input-placeholder {
      font: 1rem $font-base;
      color: $theme-dark;
    }
  }
}

.el-autocomplete-suggestion li{
  height: 34px;
  font: 1rem $font-base;
  color: $theme-dark;
  display: flex;
  align-items: center;
}

.el-scrollbar__thumb{
  background: $theme-primary;
}

.el-scrollbar {
  > .el-scrollbar__bar {
    opacity: 1;
  }
}

.el-autocomplete-suggestion__wrap{
  max-height: 180px !important;
}

body{
  &[class^="kt-"]{
    height: auto !important;
    overflow: auto !important;
  }
}
